export enum DocumentStatus {
    //
    // Summary:
    //     Rejected
    Rejected = 1,
    //
    // Summary:
    //     Approved
    Approved = 2,
    //
    // Summary:
    //     Approved By Compliance Officer
    ApprovedByComplianceOfficer = 3,
    //
    // Summary:
    //     Pending review.
    PendingReview = 4,
    //
    // Summary:
    //     Pending automatic verification.
    PendingAutomaticVerification = 5,
    //
    // Summary:
    //     Automatically approved.
    AutomaticallyApproved = 6,
    //
    // Summary:
    //     Automatically approved.
    AutomaticallyRejected = 7
}
