export enum BoardFormType {

    SignUp = "signUp",
    Login = "login",
    ForgotPassword = "forgotPassword",
    ResetPassword = "resetPassword",
    CheckInbox = "checkInbox",
    ChangePassword = "changePassword",
    VerificationCode = "verificationCode"
}
