import {SignUpMetaSocial} from "signup/core/type/SignUpMetaSocial";
import {SignUpMetaManual} from "signup/core/type/SignUpMetaManual";
import {RegLegalData} from "signup/core/config/RegLegalData";
import {Configuration} from "signup/core/config/Configuration";
import {Logger} from "platform/logger/Logger";
import {SignUpRequest} from "signup/protocol/SignUpRequest";
import {RequestBuilder} from "signup/core/engine/RequestBuilder";
import {Http, HttpReject} from "platform/network/http/Http";
import {SignUpResponse} from "signup/protocol/SignUpResponse";
import Utils from "platform/util/Utils";
import {SignUpResult} from "signup/core/type/SignUpResult";
import {Validation, ValidationErrors} from "signup/core/engine/Validation";
import {SocialMediaType} from "signup/enum/SocialMediaType";
import {SocialLogin} from "signup/core/engine/SocialLogin";
import WebUtil from "platform/util/WebUtil";
import {LoginInWithToken} from "signup/core/type/LoginInWithToken";
import {OAuthResponse} from "signup/core/type/OAuthResponse";
import {IConfig} from "signup/core/config/IConfig";

export class SignUpEngine {

    private static _Logged: Logger = Logger.Of("SignUpEngine");

    private constructor() {
    }

    public static PerformManual = async (meta: SignUpMetaManual, CountryCode: string): Promise<SignUpResult> => {
        const ld: RegLegalData = await Configuration.GetLegalData(meta.brandId, meta.langCode);
        const errors: ValidationErrors = Validation.ValidateManual(ld, meta);
        if (Utils.isObjectEmpty(errors)) {
            this._Logged.info(`Perform manual signup. Email: ${meta.email}`);
            const request: SignUpRequest = RequestBuilder.BuildManual(ld, meta, CountryCode);
            return this.ProcessSignUp(ld, request);
        }
        return this.ValidationErrorResponse(errors);
    }

    public static SocialToken = async (meta: SignUpMetaSocial): Promise<string> => {
        const ld: RegLegalData = await Configuration.GetLegalData(meta.brandId, meta.langCode);
        let token: string;
        if (meta.socialType === SocialMediaType.Apple) {
            token = await SocialLogin.appleSignUp(ld.AppleClientId, meta.redirectURL);
        } else if (meta.socialType === SocialMediaType.Facebook) {
            token = await SocialLogin.facebookSignUp(ld.FacebookAppId);
        } else if (meta.socialType === SocialMediaType.Google) {
            token = await SocialLogin.googleSignUp(ld.GoogleClientId);
        }
        return token;
    }

    public static PerformSocial = async (meta: SignUpMetaSocial, CountryCode: string): Promise<SignUpResult> => {
        const ld: RegLegalData = await Configuration.GetLegalData(meta.brandId, meta.langCode);
        const errors: ValidationErrors = Validation.ValidateSocial(ld, meta);
        if (Utils.isObjectEmpty(errors)) {
            this._Logged.info(`Perform social signup. Type: ${meta.socialType}`);
            if (Utils.isEmpty(meta.token)) {
                meta.token = await this.SocialToken(meta);
            }
            const request: SignUpRequest = RequestBuilder.BuildSocial(ld, meta, CountryCode);
            return this.ProcessSignUp(ld, request);
        }
        return this.ValidationErrorResponse(errors);
    }

    private static ValidationErrorResponse = (errors: ValidationErrors): SignUpResult => {
        const errorMessage: string = Object.keys(errors).map((key: string) => `${key}: ${errors[key]}`).join("; ");
        this._Logged.warn(`SignUp errors: ${errorMessage}`);
        return {
            success: false,
            errorMessage
        };
    }

    private static ProcessSignUp = async (ld: RegLegalData, request: SignUpRequest): Promise<SignUpResult> => {
        const answer: [HttpReject, string] = await Utils.to(
            Http.post(`${ld.ApiUrl}/register`, JSON.stringify(request), [
                {name: "Content-Type", value: "application/json"}
            ], false, 300000)
        );
        if (answer[0]) {
            return {
                success: false,
                errorMessage: "Network error"
            };
        } else {
            try {
                const response: SignUpResponse = JSON.parse(answer[1]);
                const {success, error_code, error_message, redirect_url} = response;
                return {
                    success,
                    redirectUrl: redirect_url,
                    errorCode: error_code,
                    errorMessage: error_message
                };
            } catch (e) {
                return {
                    success: false,
                    errorMessage: "Failed parse response"
                };
            }
        }
    }

    public static SocialLogin = async ({
                                           brandId,
                                           langCode,
                                           socialType,
                                           token
                                       }: LoginInWithToken): Promise<string> => {
        const iConfig: IConfig = Configuration.Get();
        let targetUrl: string = iConfig.RegApiSocial;
        targetUrl = targetUrl.replace("{brand}", brandId.toString());
        targetUrl = targetUrl.replace("{lang}", langCode);
        targetUrl = targetUrl.replace("{provider}", socialType);

        if (socialType === SocialMediaType.Facebook) {
            targetUrl = targetUrl.replace("{access_token}", token);
            targetUrl = WebUtil.deleteGetParam(targetUrl, "jwt");
        } else {
            targetUrl = targetUrl.replace("{jwt}", token);
            targetUrl = WebUtil.deleteGetParam(targetUrl, "access_token");
        }
        const answerOAuth: [HttpReject, OAuthResponse] = await Utils.to(Http.getJson(targetUrl));
        if (answerOAuth[0]) {
            return Promise.reject({status: answerOAuth[0]?.status});
        } else {
            const response: OAuthResponse = answerOAuth[1];
            if (parseInt(response?.error_code) === 21) {
                return Promise.reject({error_code: 21});
            } else if (Utils.isEmpty(response?.token)) {
                return Promise.reject({error_code: 101});
            } else {
                return Promise.resolve(response.token);
            }
        }
    }
}
