import Utils from "platform/util/Utils";

enum MigrationDialogType {

    Fork = "fork",
    Blocking = "blocking" ,
}

namespace MigrationDialogType {

    export function deserialize(v: string): MigrationDialogType {
        if (Utils.isNotNull(v)) {
            switch (v) {
                case "Fork":
                    return MigrationDialogType.Fork;
                case "Blocking":
                    return MigrationDialogType.Blocking;
            }
        }
        return null;
    }
}

export {
    MigrationDialogType
};
