import Utils from "platform/util/Utils";
import {PageType} from "kbd/enum/PageType";

enum BIEventType {

    // Login
    LoginAttempt = "Login Attempt",
    LoginDecision = "Login Decision",

    // KYC
    OBOnBoardingSessionStarted = "OB OnBoarding Session started",
    OBOnBoardingNavigation = "OB OnBoarding Navigation",
    OBOnBoardingStarted = "OB OnBoarding started",
    OBOnBoardingEnded = "OB OnBoarding ended",
    OBError = "OB Error",
    OBWelcomeStep = "OB Welcome Step",
    OBPersonalInformation = "OB Personal Information",
    OBAddress = "OB Address",
    OBFinancialDetails = "OB Financial Details",
    OB_PEP_US = "OB PEP US",
    OBTradingFamiliarity = "OB Trading Familiarity",
    OBTradingExperience = "OB Trading Experience",
    OBTradingKnowledge123 = "OB Trading Knowledge 1 2 3",
    OBTradingKnowledge456 = "OB Trading Knowledge 4 5 6",
    OBTradingIntentions = "OB Trading Intentions",
    OBTINValidation = "OB TIN Validation",
    OBAppropriatenessMessage = "OB Appropriateness Message",
    OBAppropriatenessResponse = "OB Appropriateness Response",
    OBTradingProfile = "OB Trading Profile",
    OBIDVerification = "OB ID Verification",
    OBAddressVerification = "OB Address Verification",
    OBPaymentVerification = "OB Payment Verification",
    OBCongratulations = "OB Congratulations",
    OBBack = "OB Back",
    OBDoItLater = "OB Do it Later",

    DocumentsSiteLoaded = "Documents Site Loaded",
    DocumentUploadSuccess = "Document upload success",
    DocumentCanceledSuccess = "Document canceled success",
    DocumentError = "Document Error",
}

namespace BIEventType {

    export function eventType(pageType: PageType): BIEventType {
        if (Utils.isNotNull(pageType)) {
            switch (pageType) {
                case PageType.KycPersonalInfo:
                    return BIEventType.OBPersonalInformation;
                case PageType.KycAddress:
                    return BIEventType.OBAddress;
                case PageType.KycFinancialDetails:
                case PageType.KycFinancialDetailsInternational:
                    return BIEventType.OBFinancialDetails;
                case PageType.KycTradingFamiliarity:
                    return BIEventType.OBTradingFamiliarity;
                case PageType.KycTradingExperience:
                    return BIEventType.OBTradingExperience;
                case PageType.KycTradingExperienceKnowledge:
                    return BIEventType.OBTradingKnowledge123;
                case PageType.KycTradingExperienceKnowledge2:
                    return BIEventType.OBTradingKnowledge456;
                case PageType.KycTradingExperienceIntentions:
                    return BIEventType.OBTradingIntentions;
                case PageType.KycTradingProfile:
                    return BIEventType.OBTradingProfile;
                case PageType.KycIdentityVerification:
                    return BIEventType.OBIDVerification;
                case PageType.KycAddressVerification:
                    return BIEventType.OBAddressVerification;
                case PageType.KycPaymentVerification:
                    return BIEventType.OBPaymentVerification;
            }
        }
        return null;
    }
}

export {
    BIEventType
};
