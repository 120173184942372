import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "kbd/enum/ServiceType";
import BoardEngine from "kbd/core/engine/BoardEngine";
import BoardReducer from "kbd/core/redux/board/BoardReducer";
import {BoardState} from "kbd/core/state/BoardState";

export class BoardService implements Service<BoardEngine, BoardState> {

    private static _instance: BoardService;

    private constructor() {
    }

    public static instance(): BoardService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.Board;
    }

    public reducers(): Array<Reducer<any>> {
        return [BoardReducer.instance()];
    }

    public engine(): BoardEngine {
        return BoardEngine.instance();
    }

    public state(): BoardState {
        return BoardState.instance();
    }
}
