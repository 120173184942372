import {TSMap} from "typescript-map";
import {AvailableLanguage} from "kbd/protocol/response/LoginConfigurationResponse";
import {LangCode} from "platform/enum/LangCode";
import {Win} from "platform/integration/win/Win";
import {ProductType} from "kbd/entry/ProductType";

export class AppState {

    private static _instance: AppState;
    private _languages: TSMap<LangCode, AvailableLanguage> = new TSMap();
    private _product: ProductType;
    private _userId: number;
    private _newWin: Win;
    private _sessionStartTime: number = 0;

    private constructor() {
    }

    public static instance(): AppState {
        return this._instance || (this._instance = new this());
    }

    public startSession(): void {
        this._sessionStartTime = new Date().getTime();
    }

    public get sessionStartTime(): number {
        return this._sessionStartTime;
    }

    public addLanguage(langCode: LangCode, language: AvailableLanguage): void {
        this._languages.set(langCode, language);
    }

    public getLanguage(langCode: LangCode): AvailableLanguage {
        return this._languages.get(langCode);
    }

    public get product(): ProductType {
        return this._product;
    }

    public set product(value: ProductType) {
        this._product = value;
    }

    public get userId(): number {
        return this._userId;
    }

    public set userId(value: number) {
        this._userId = value;
    }

    public get newWin(): Win {
        return this._newWin;
    }

    public set newWin(value: Win) {
        this._newWin = value;
    }
}
