import * as Bowser from "bowser";
import React from "react";
import {RouterProvider} from "react-router5";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider as MuiThemeProvider} from "@mui/material/styles";
import {Logger} from "platform/logger/Logger";
import Platform, {SetupResult} from "platform/Platform";
import ConfigManager from "kbd/core/configuration/ConfigManager";
import {TranslationLoaderImpl} from "kbd/core/translation/TranslationLoaderImpl";
import WebEnvironment from "platform/environment/WebEnvironment";
import {Router5} from "platform/router/router5/Router5";
import {Routes} from "kbd/core/router/Routes";
import {PageType} from "kbd/enum/PageType";
import WebUtil from "platform/util/WebUtil";
import {AppService} from "kbd/core/service/AppService";
import Utils from "platform/util/Utils";
import {ProductType} from "kbd/entry/ProductType";
import {Service} from "platform/service/Service";
import {DisableLoaderOverlay, SetAppReady} from "platform/redux/core/CoreActions";
import {BrandType} from "platform/enum/BrandType";
import {StoreState} from "kbd/core/redux/StoreState";
import Script from "platform/network/script/Script";
import {SetProduct} from "kbd/core/redux/app/AppReduxActions";
import {MuiFonts} from "platform/font/MuiFonts";
import {AppState} from "kbd/core/state/AppState";
import {Environment} from "platform/enum/Environment";
import {XhrHeaders} from "platform/network/xhr/XhrHeaders";

const PlatformType: string = WebUtil.urlParam("platform");
const BowserDetails: Bowser.Parser.Details = WebUtil.BowserDetails();
XhrHeaders.browserName = BowserDetails?.name;
XhrHeaders.browserVersion = BowserDetails?.version;

if (Environment.isProd()) {
    const loadSentry = async () => {
        return await import('@sentry/react');
    };
    const loadTracing = async () => {
        return await import('@sentry/tracing');
    };
    loadSentry().then((Sentry) => {
        loadTracing().then(({BrowserTracing}) => {
            try {
                Sentry.init({
                    dsn: "https://f70f23908f1842bd83553e60d5ba6f39@o1178767.ingest.sentry.io/6442786",
                    integrations: [new BrowserTracing()],
                    tracesSampleRate: 1.0,
                });
            } catch (e) {
            }
        });
    });
}

interface IProps {

    Desktop: React.ComponentType;
    Mobile: React.ComponentType;
    product: ProductType;
    defaultPage: PageType;
    startPath?: string;
    services?: Array<{service: Service<any, any>, props?: any}>;
}

const Boot = async (props: IProps): Promise<void> => {
    Utils.checkNotNull(props);
    const logger: Logger = Logger.Of("Boot");
    logger.info("Start boot. Product: " + props.product);
    const appState: AppState = AppState.instance();
    appState.product = props.product;

    let services: Array<{service: Service<any, any>, props?: any}> = [
        {service: AppService.instance()}
    ];
    if (Utils.isArrayNotEmpty(props.services)) {
        services = services.concat(props.services);
    }
    const setupResult: SetupResult = await Platform.setup({
        configurationLoader: ConfigManager.loader(),
        translationLoader: TranslationLoaderImpl.instance(),
        environment: WebEnvironment.instance(),
        router: Router5.instance({
            routes: Routes.routes(),
            defaultRoute: props.defaultPage,
            defaultParams: WebUtil.getParamsToObject()
        }),
        startPath: props.startPath,
        services
    });
    const isMobile: boolean = WebUtil.isMobile();
    const brand: BrandType = Platform.reduxState<StoreState>().core.brand;
    logger.info("Platform setup finished. Brand: " + brand + ". Default page type: " + props.defaultPage);
    Script.injectLink("/css/" + brand + (isMobile ? "-mobile" : "") + ".css");

    const muiTheme = createTheme({
        typography: {
            fontFamily: MuiFonts.get(brand).join(","),
        }
    });

    Platform.dispatch(SetProduct({product: props.product}));
    Platform.dispatch(SetAppReady({ready: true}));
    if (PlatformType === "new") {
        Platform.dispatch(DisableLoaderOverlay({}));
    }
    const root = ReactDOM.createRoot(document.getElementById("RootElement"));
    root.render(
        <Provider store={Platform.store()}>
            <MuiThemeProvider theme={muiTheme}>
                <RouterProvider router={Platform.router().router()}>
                    <>
                        {isMobile ? <props.Mobile /> : <props.Desktop />}
                    </>
                </RouterProvider>
            </MuiThemeProvider>
        </Provider>
    );
    return Promise.resolve();
};

export {
    Boot
};
