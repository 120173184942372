export enum PageType {

    // OnBoarding Pages
    SignUp = "SignUp",
    Login = "Login",
    ForgotPassword = "ForgotPassword",
    ResetPassword = "ResetPassword",
    CheckInbox = "CheckInbox",
    ChangePassword = "ChangePassword",
    Verification = "Verification",

    // KYC Pages
    KycWelcome = "KycWelcome",
    KycPersonalInfo = "KycPersonalInfo",
    KycAddress = "KycAddress",
    KycFinancialDetails = "KycFinancialDetails",
    KycTradingFamiliarity = "KycTradingFamiliarity",
    KycTradingExperience = "KycTradingExperience",
    KycTradingExperienceKnowledge = "KycTradingExperienceKnowledge",
    KycTradingExperienceKnowledge2 = "KycTradingExperienceKnowledge2",
    KycTradingExperienceIntentions = "KycTradingExperienceIntentions",
    KycIdentityVerification = "KycIdentityVerification",
    KycAddressVerification = "KycAddressVerification",
    KycPaymentVerification = "KycPaymentVerification",
    KycTradingProfile = "KycTradingProfile",
    KycFinancialDetailsInternational = "KycFinancialDetailsInternational",
    KycSignature = "KycSignature",
    KycCongratulation = "KycCongratulation",
    KycAppropriate = "KycAppropriate",
    KycInAppropriate = "KycInAppropriate",
    KycFilledOut = "KycFilledOut",
    KycCnmv = "KycCnmv",

    // Deposit Pages
    DepositTopUp = "DepositTopUp",
    DepositIFrame = "DepositIFrame",

    // Professional Pages
    ProfessionalWelcome = "ProfessionalWelcome",
    ProfessionalQuestionnaire = "ProfessionalQuestionnaire",
    ProfessionalIllegal = "ProfessionalIllegal",
    ProfessionalSuccessful = "ProfessionalSuccessful",
    ProfessionalDocuments = "ProfessionalDocuments",

    // Documents Pages
    DocumentsOverview = "DocumentsOverview",
    DocumentPage = "DocumentPage",
    DocumentPaymentPage = "DocumentPaymentPage",

    // DepositLimits Pages
    DepositLimitQuestions = "DepositLimitQuestions",
    DepositLimitSuccessful = "DepositLimitSuccessful",
    DepositLimitAlreadyFilled = "DepositLimitAlreadyFilled",
    DepositLimitWarning = "DepositLimitWarning",

    // ShareInformation Pages
    ShareInformationNotice = "ShareInformationNotice",
    ShareInformationDetails = "ShareInformationDetails"
}
