import Utils from "platform/util/Utils";
import {ContactInfoSource} from "signup/enum/ContactInfoSource";

enum SocialMediaType {

    Google = "google",
    Facebook = "facebook",
    Apple = "apple"
}

namespace SocialMediaType {

    export function From(source: ContactInfoSource): SocialMediaType {
        if (Utils.isNotNull(source)) {
            switch (source) {
                case ContactInfoSource.Apple:
                    return SocialMediaType.Apple;
                case ContactInfoSource.Facebook:
                    return SocialMediaType.Facebook;
                case ContactInfoSource.Google:
                    return SocialMediaType.Google;
            }
        }
        return null;
    }
}

export {
    SocialMediaType
};
