import {Logger} from "platform/logger/Logger";
import {AppState} from "kbd/core/state/AppState";
import {ProductType} from "kbd/entry/ProductType";

export class ReadyState {

    private static _logger: Logger = Logger.Of("ReadyState");
    private static _hasLDBrandProps: boolean;
    private static _hasComplianceForm: boolean;
    private static _ready: boolean;

    private constructor() {
    }

    public static get ready(): boolean {
        return this._ready;
    }

    public static set hasLDBrandProps(value: boolean) {
        this._hasLDBrandProps = value;
        this.checkAppReady();
    }

    public static set hasComplianceForm(value: boolean) {
        this._hasComplianceForm = value;
        this.checkAppReady();
    }

    private static checkAppReady(): void {
        this._logger.debug(`Has LD brand props ${this._hasLDBrandProps}`);
        const appState: AppState = AppState.instance();
        if (!this._ready && this._hasLDBrandProps && (appState.product !== ProductType.Kyc || this._hasComplianceForm)) {
            this._ready = true;
            document.getElementById("RootElement")?.setAttribute("AppReady", "true");
        }
    }
}
