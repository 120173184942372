import {initializeApp, FirebaseOptions} from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    signInWithCredential,
    GoogleAuthProvider,
    FacebookAuthProvider
} from "firebase/auth";
import {Logger} from "platform/logger/Logger";
import {FirebaseAuthLogin} from "signup/core/type/FirebaseAuthLogin";
import {Configuration} from "signup/core/config/Configuration";
import Utils from "platform/util/Utils";

export class FirebaseAuth {

    private static _logger: Logger = Logger.Of("FirebaseAuth");
    private static _auth: any;

    private static GetAuth = (brandId: number): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            this._logger.debug("Get firebase auth.");
            if (Utils.isNull(this._auth)) {
                const {Firebase} = Configuration.Get();
                const firebaseConfig: FirebaseOptions = Firebase[brandId?.toString()];
                if (firebaseConfig) {
                    const app = initializeApp(firebaseConfig);
                    this._auth = getAuth(app);
                } else {
                    reject(new Error(`Empty Firebase config for brandId ${brandId}`));
                }
            }
            resolve(this._auth);
        });
    }

    private static Reject = (sError: string): Promise<any> => {
        this._logger.warn(sError);
        return Promise.reject(new Error(sError));
    }

    public static Login = async ({username, password, brandId}: FirebaseAuthLogin): Promise<string> => {
        if (username && password && brandId) {
            const auth: [Error, any] = await Utils.to(this.GetAuth(brandId));
            if (auth[1]) {
                this._logger.info(`Try to SignIn with: ${username}`);
                const credential: [error: {code: number, message: string}, any] = await Utils.to(signInWithEmailAndPassword(auth[1], username, password));
                if (credential[1]) {
                    const idToken: string = await credential[1].user.getIdToken(true);
                    return idToken ? idToken : this.Reject("Firebase token failed");
                } else {
                    return this.Reject(`SignIn failed with. Error code: ${credential[0].code}, msg: ${credential[0].message}`);
                }
            } else {
                return Promise.reject(auth[0]);
            }
        } else {
            return this.Reject("User / pass / brand empty.");
        }
    }

    public static LoginWithFacebook = async (accessToken: string, brandId: number): Promise<string> => {
        if (accessToken && brandId) {
            const auth: [Error, any] = await Utils.to(this.GetAuth(brandId));
            if (auth[1]) {
                this._logger.info("Try to SignIn with Facebook");
                const credential = FacebookAuthProvider.credential(accessToken);
                const result = await signInWithCredential(auth[1], credential);
                const idToken: string = await result?.user?.getIdToken(true);
                return idToken ? idToken : this.Reject("Firebase token failed");
            } else {
                return Promise.reject(auth[0]);
            }
        } else {
            return this.Reject("Access token / brand empty.");
        }
    }

    public static LoginWithGoogle = async (token: string, brandId: number): Promise<string> => {
        if (token && brandId) {
            const auth: [Error, any] = await Utils.to(this.GetAuth(brandId));
            if (auth[1]) {
                this._logger.info("Try to SignIn with Google");
                const credential = GoogleAuthProvider.credential(token);
                const result = await signInWithCredential(auth[1], credential);
                const idToken: string = await result?.user?.getIdToken(true);
                return idToken ? idToken : this.Reject("Firebase token failed");
            } else {
                return Promise.reject(auth[0]);
            }
        } else {
            return this.Reject("Token / brand empty.");
        }
    }
}
