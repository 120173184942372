import {Route, Router} from "router5";
import {PageType} from "kbd/enum/PageType";
import Platform from "platform/Platform";
import {TSMap} from "typescript-map";

export class Routes {

    private static activators: TSMap<PageType, () => () => boolean> = new TSMap();
    private static blocker: () => () => boolean = () => () => {
        return false;
    };

    private static ROUTES: Route[] = [
        // OnBoard Routes
        {name: PageType.SignUp, path: "/sign-up"},
        {name: PageType.Login, path: "/login"},
        {name: PageType.ForgotPassword, path: "/forgot-password"},
        {name: PageType.ResetPassword, path: "/reset-password"},
        {name: PageType.CheckInbox, path: "/check-inbox"},
        {name: PageType.ChangePassword, path: "/change-password"},
        {name: PageType.Verification, path: "/verification"},
        // Kyc Routes
        {name: PageType.KycWelcome, path: "/kyc-welcome?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycPersonalInfo, path: "/kyc-personal-info?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycAddress, path: "/kyc-address?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycFinancialDetails, path: "/kyc-financial-details?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycFinancialDetailsInternational, path: "/kyc-financial-details-international?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingFamiliarity, path: "/kyc-trading-familiarity?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingExperience, path: "/kyc-trading-experience?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingExperienceKnowledge, path: "/kyc-trading-experience-knowledge?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingExperienceKnowledge2, path: "/kyc-trading-experience-knowledge-2?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingExperienceIntentions, path: "/kyc-trading-experience-intentions?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycIdentityVerification, path: "/kyc-identity-verification?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycAddressVerification, path: "/kyc-proof-of-address?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycPaymentVerification, path: "/kyc-payment-verification?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycTradingProfile, path: "/kyc-trading-profile?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycSignature, path: "/kyc-signature?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycCongratulation, path: "/kyc-congratulation?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycAppropriate, path: "/kyc-appropriate?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycInAppropriate, path: "/kyc-in-appropriate?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycFilledOut, path: "/kyc-filled-out?action&theme&platform&bi&skipWelcome&pmLog"},
        {name: PageType.KycCnmv, path: "/kyc-cnmv?action&theme&platform&bi&skipWelcome&pmLog"},
        // Deposit Routes
        {name: PageType.DepositTopUp, path: "/deposit-top-up?action&theme&platform&bi&skipWelcome&pmLog"},
        // Professional Routes
        {name: PageType.ProfessionalWelcome, path: "/professional-welcome?theme&platform&bi&pmLog"},
        {name: PageType.ProfessionalQuestionnaire, path: "/professional-questionnaire?theme&platform&bi&pmLog"},
        {name: PageType.ProfessionalIllegal, path: "/professional-illegal?theme&platform&bi&pmLog"},
        {name: PageType.ProfessionalSuccessful, path: "/professional-successful?theme&platform&bi&pmLog"},
        {name: PageType.ProfessionalDocuments, path: "/professional-documents?theme&platform&bi&pmLog"},
        // Documents Routes
        {name: PageType.DocumentsOverview, path: "/documents-overview?theme&platform&bi&pmLog"},
        {name: PageType.DocumentPage, path: "/document-page?theme&platform&bi&pmLog"},
        {name: PageType.DocumentPaymentPage, path: "/document-payment-page?theme&platform&bi&pmLog"},
        // Deposit limits Routes
        {name: PageType.DepositLimitQuestions, path: "/deposit-limits?theme&platform&bi&pmLog"},
        {name: PageType.DepositLimitSuccessful, path: "/deposit-limits-successful?theme&platform&bi&pmLog"},
        {name: PageType.DepositLimitAlreadyFilled, path: "/deposit-limits-filled?theme&platform&bi&pmLog"},
        {name: PageType.DepositLimitWarning, path: "/deposit-limits-warning?theme&platform&bi&pmLog"},
        // Share information Routes
        {name: PageType.ShareInformationNotice, path: "/share-information-notice?theme&platform&bi&pmLog"},
        {name: PageType.ShareInformationDetails, path: "/share-information-details?theme&platform&bi&pmLog"},
    ];

    private constructor() {
    }

    public static routes(): Route[] {
        return this.ROUTES;
    }

    public static addActivator(pageType: PageType, activator: () => () => boolean): void {
        if (pageType && activator) {
            this.activators.set(pageType, activator);
            const router: Router = Platform.router().router();
            router.canActivate(pageType, activator);
        }
    }

    public static canActivate(pageType: PageType): boolean {
        const activator: () => () => boolean = this.activators.get(pageType);
        if (activator) {
            return activator()();
        }
        return true;
    }

    public static blockAllExcept(pageType: PageType): void {
        Routes.ROUTES.forEach((route: Route) => {
            if (route.name !== pageType) {
                this.addActivator(route.name as PageType, this.blocker)
            }
        });
    }
}
