import Utils from "platform/util/Utils";
import {PageType} from "kbd/enum/PageType";

enum ProofDocumentType {
    //
    // Summary:
    //     Proof of identification.
    ProofOfId = 1,
    //
    // Summary:
    //     Proof of residence.
    ProofOfResidence = 2,
    //
    // Summary:
    //     Proof of credit card.
    ProofOfCC = 3,
    //
    // Summary:
    //     Miscellaneous.
    Miscellaneous = 4,
    //
    // Summary:
    //     Proof of bank details.
    ProofOfBankDetails = 5,
    //
    // Summary:
    //     Proof of portfolio.
    ProofOfPortfolio = 6,
    //
    // Summary:
    //     Proof of trading experience.
    ProofOfTradingExperience = 7,
    //
    // Summary:
    //     Proof of work experience.
    ProofOfWorkExperience = 8
}

namespace ProofDocumentType {

    export function fromPage(pageType: PageType): ProofDocumentType {
        if (Utils.isNotNull(pageType)) {
            switch (pageType) {
                case PageType.KycIdentityVerification:
                    return ProofDocumentType.ProofOfId;
                case PageType.KycAddressVerification:
                    return ProofDocumentType.ProofOfResidence;
                case PageType.KycPaymentVerification:
                    return ProofDocumentType.ProofOfCC;
            }
        }
        return null;
    }
}

export {
    ProofDocumentType
};
