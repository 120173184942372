import Utils from "platform/util/Utils";
import {QuestionKey} from "kbd/protocol/QuestionKey";

enum FieldType {

    // On Board
    FullName = "FullName",
    Email = "email",
    Username = "username",
    SignUpCountryCode = "signUpCountryCode",
    SignUpPassword = "signUpPassword",
    LoginPassword = "loginPassword",
    ReferralCode = "referralCode",
    HaveReferralCode = "haveReferralCode",
    NewPassword = "newPassword",
    ConfirmPassword = "confirmPassword",
    VerificationCode = "verificationCode",
    Token = "Token",

    // Personal Info
    FirstName = "FirstName", // string
    LastName = "LastName", // string
    CountryCallingCode = "CountryCode", // string
    PhoneNumber = "PhoneNumber", // string
    RegistrationEmail = "Email", // string
    BirthDate = "BirthDate",
    BirthDay = "birthDay", // number
    BirthMonth = "birthMonth", // number
    BirthYear = "birthYear", // number

    // Address
    Address = "Address", // string
    City = "City", // string
    PostalCode = "PostalCode", // string
    CountryOfResidence = "CountryOfResidenceId", // string
    CountryOfBirth = "CountryOfBirthId", // string
    Nationality = "CountryOfNationalityId", // string

    // Financial details
    EducationLevel = "EducationLevel", // number
    AreaOfEducationId = "AreaOfEducationId", // number
    EmploymentStatus = "EmploymentStatus", // number
    Industry = "IndustryId", // number
    EmploymentDetailsType = "EmploymentDetailsType", // number
    IndustryDescription = "IndustryDescription", // string
    PreviousIndustryId = "PreviousIndustryId", // number
    PreviousEmploymentDetailsType = "PreviousEmploymentDetailsType", // number
    CountryOfEmployment = "CountryOfEmploymentIds", // string
    AnnualIncome = "EstimatedAnnualIncome", // number
    SavingsAndInvestments = "SavingAndInvestmentId", // number
    SourceOfWealth = "SourceOfWealthIds",  // array of number[]
    PassportNumber = "IdentityCardNumber", // string
    UsCitizen = "IsUSACitizen", // boolean
    PoliticalExposedPerson = "IsPoliticalPerson", // boolean
    ExperienceLength = "ExperienceLength", // number

    // Trading familiarity
    ListedSharesId = "ListedSharesId",
    ExchangeTradedFundsFamiliarityId = "ExchangeTradedFundsFamiliarityId",
    FinancialDerivativesFamiliarityId = "FinancialDerivativesFamiliarityId",
    CFDsFamiliarityId = "CFDsFamiliarityId",
    WarrantsFamiliarityId = "WarrantsFamiliarityId",
    // Trading experience
    ExperienceTrading = "ForexAndCFDsYearsOfExperience",
    TradingVolume = "TradingVolumeInTheLast12MonthsType",
    TradingFrequency = "FrequencyOfTradingId",
    ExperienceInFinancialServices = "ProfessionalQualificationTypeId",
    // Trading knowledge 1
    MaxLeveragedAmount = "ComplianceQuizLeverageQuestionAnwserId",
    TradingSharePrice = "ComplianceQuizCDFShareQuestionAnwserId",
    TradingStopLossOrder = "ComplianceQuizStopLossOrderAnswerId",
    // Trading knowledge 2
    MarketRateAmount = "ComplianceQuizForexQuestionAnswerId",
    PotentialProfitOrLoose = "ComplianceQuizMarketMovesQuestionAnswerId",
    TradingRiskCFDAmount = "ComplianceQuizRiskQuestionAnswerId",
    // Trading intentions
    PurposeOfTrading = "TradingAccountPurpose",
    InvestmentHorizonTypeId = "InvestmentHorizonTypeId",
    PlannedTradingAmountPerYear = "PlannedTradingAmountPerYearId",
    SourceOfTradingFunds = "SourceOfTradingFundsIds",
    MethodOfDeposit = "MethodsOfDepositId",
    CountryOfTaxRegistration = "CountryOfTaxRegistrationId",
    TaxIdentificationNumber = "TIN",
    IDontHaveTIN = "DoesNotOwnTinNumber",

    // Professional transaction
    IsAverageAmountOfCarriedTransactionsPerQuarterExceedsLimit = "IsAverageAmountOfCarriedTransactionsPerQuarterExceedsLimit",
    TotalVolumeOfTransactionsLast24MonthsId = "TotalVolumeOfTransactionsLast24MonthsId",
    BrokersNames = "BrokersNames",
    // Professional sector
    IsProfessionalExperienceExceedsOneYear = "IsProfessionalExperienceExceedsOneYear",
    EmploymentDetailsId = "EmploymentDetailsId",
    OtherEmploymentDetails = "OtherEmploymentDetails",
    KnowledgeOfCFDTrading = "KnowledgeOfCFDTrading",
    // Professional investment
    IsSizeOfFinancialInstrumentPortfolioExceedsLimit = "IsSizeOfFinancialInstrumentPortfolioExceedsLimit",
    SharesPortfolioSizeId = "SharesPortfolioSizeId",
    DebtInstrumentsPortfolioSizeId = "DebtInstrumentsPortfolioSizeId",
    DerivativesPortfolioSizeId = "DerivativesPortfolioSizeId",
    CashDepositsAndSavingsPortfolioSizeId = "CashDepositsAndSavingsPortfolioSizeId",
    ExchangeTradedProductsPortfolioSizeId = "ExchangeTradedProductsPortfolioSizeId",
    // Professional brokers/policy
    ProfessionalPolicy = "ProfessionalPolicy",

    // Deposit Limits
    ProductGovernanceInvestingObjectiveTypeId = "ProductGovernanceInvestingObjectiveTypeId",
    ProductGovernanceDesiredRiskTypeId = "ProductGovernanceDesiredRiskTypeId",
    ProductGovernanceYearlyDisposableIncomeCategoryId = "ProductGovernanceYearlyDisposableIncomeCategoryId",
    YearlyDisposableIncome = "YearlyDisposableIncome",
}

namespace FieldType {

    export function formFields(type: FieldType): FieldType[] {
        return FieldType.extraFields(type) || [type];
    }

    export function extraFields(type: FieldType): FieldType[] {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case FieldType.PhoneNumber:
                    return [
                        FieldType.CountryCallingCode,
                        FieldType.PhoneNumber
                    ];
                case FieldType.BirthDate:
                    return [
                        FieldType.BirthDay,
                        FieldType.BirthMonth,
                        FieldType.BirthYear
                    ];
                case FieldType.Address:
                    return [
                        FieldType.Address,
                        FieldType.CountryOfResidence,
                        FieldType.City,
                        FieldType.PostalCode
                    ];
            }
        }
        return null;
    }

    export function questionKey(type: FieldType): QuestionKey {
        if (Utils.isNotNull(type)) {
            switch (type) {
                case FieldType.EducationLevel:
                    return QuestionKey.EducationLevel;
                case FieldType.AreaOfEducationId:
                    return QuestionKey.AreaOfEducation;
                case FieldType.EmploymentStatus:
                    return QuestionKey.EmploymentStatus;
                case FieldType.Industry:
                    return QuestionKey.BusinessNature;
                case FieldType.EmploymentDetailsType:
                    return QuestionKey.EmploymentDetailsType;
                case FieldType.PreviousIndustryId:
                    return QuestionKey.PreviousBusinessNature;
                case FieldType.PreviousEmploymentDetailsType:
                    return QuestionKey.PreviousEmploymentDetailsType;
                case FieldType.AnnualIncome:
                    return QuestionKey.EstimatedAnnualIncome;
                case FieldType.SavingsAndInvestments:
                    return QuestionKey.EstimatedNetWorth;
                case FieldType.SourceOfWealth:
                    return QuestionKey.SourceOfTotalWealthType;
                case FieldType.ExperienceLength:
                    return QuestionKey.ExperienceLength;
                case FieldType.ListedSharesId:
                    return QuestionKey.ProductOrFinancialInstrumentType_ListedShares;
                case FieldType.ExchangeTradedFundsFamiliarityId:
                    return QuestionKey.ProductOrFinancialInstrumentType_ExchangeTradedFunds;
                case FieldType.FinancialDerivativesFamiliarityId:
                    return QuestionKey.ProductOrFinancialInstrumentType_FinancialDerivatives;
                case FieldType.CFDsFamiliarityId:
                    return QuestionKey.ProductOrFinancialInstrumentType_CFDs;
                case FieldType.WarrantsFamiliarityId:
                    return QuestionKey.ProductOrFinancialInstrumentType_Warrants;
                case FieldType.ExperienceTrading:
                    return QuestionKey.YearsOfExperience;
                case FieldType.TradingVolume:
                    return QuestionKey.TradingVolumeInTheLast12MonthsType;
                case FieldType.TradingFrequency:
                    return QuestionKey.TradingFrequencyType;
                case FieldType.ExperienceInFinancialServices:
                    return QuestionKey.ProfessionalQualificationType;
                case FieldType.MaxLeveragedAmount:
                    return QuestionKey.ComplianceQuizLeverageQuestionAnwsers;
                case FieldType.TradingSharePrice:
                    return QuestionKey.ComplianceQuizCDFShareQuestionAnwsers;
                case FieldType.TradingStopLossOrder:
                    return QuestionKey.ComplianceQuizStopLossOrderAnswers;
                case FieldType.MarketRateAmount:
                    return QuestionKey.ComplianceQuizForexQuestionAnswers;
                case FieldType.PotentialProfitOrLoose:
                    return QuestionKey.ComplianceQuizMarketMovesQuestionAnswers;
                case FieldType.TradingRiskCFDAmount:
                    return QuestionKey.ComplianceQuizRiskQuestionAnswers;
                case FieldType.PurposeOfTrading:
                    return QuestionKey.TradingAccountPurpose;
                case FieldType.InvestmentHorizonTypeId:
                    return QuestionKey.InvestmentHorizonType;
                case FieldType.PlannedTradingAmountPerYear:
                    return QuestionKey.TradingAmountCategory;
                case FieldType.SourceOfTradingFunds:
                    return QuestionKey.FundsSource;
                case FieldType.MethodOfDeposit:
                    return QuestionKey.MethodsForFundingAccountType;
                case FieldType.TotalVolumeOfTransactionsLast24MonthsId:
                    return QuestionKey.ProfTradingAmountCategory;
                case FieldType.EmploymentDetailsId:
                    return QuestionKey.ProfEmploymentDetailsType;
                case FieldType.SharesPortfolioSizeId:
                case FieldType.DebtInstrumentsPortfolioSizeId:
                case FieldType.DerivativesPortfolioSizeId:
                case FieldType.CashDepositsAndSavingsPortfolioSizeId:
                case FieldType.ExchangeTradedProductsPortfolioSizeId:
                    return QuestionKey.ProfFinancialInstrumentPortfolioSize;
                case FieldType.ProductGovernanceInvestingObjectiveTypeId:
                    return QuestionKey.ProductGovernanceInvestingObjectiveInCFDs;
                case FieldType.ProductGovernanceDesiredRiskTypeId:
                    return QuestionKey.ProductGovernanceDesiredRisk;
                case FieldType.ProductGovernanceYearlyDisposableIncomeCategoryId:
                    return QuestionKey.ProductGovernanceYearlyDisposableIncomeCategory;
            }
        }
        return null;
    }
}

export {FieldType};
