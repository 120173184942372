import {Service} from "platform/service/Service";
import {Reducer} from "platform/redux/Reducer";
import {ServiceType} from "kbd/enum/ServiceType";
import AppReducer from "kbd/core/redux/app/AppReducer";
import AppEngine from "kbd/core/engine/AppEngine";
import {AppState} from "kbd/core/state/AppState";

export class AppService implements Service<AppEngine, AppState> {

    private static _instance: AppService;

    private constructor() {
    }

    public static instance(): AppService {
        return this._instance || (this._instance = new this());
    }

    public async setup<Props>(props?: Props): Promise<void> {
        await this.engine().setup();
    }

    public name(): string {
        return ServiceType.App;
    }

    public reducers(): Array<Reducer<any>> {
        return [AppReducer.instance()];
    }

    public engine(): AppEngine {
        return AppEngine.instance();
    }

    public state(): AppState {
        return AppState.instance();
    }
}
