export enum SignUpFieldType {

    FullName = "fullName",
    FirstName = "firstName",
    LastName = "lastName",
    PhoneCountryCode = "phoneCountryCode",
    PhoneNumber = "phoneNumber",
    Email = "email",
    Password = "password",
}
