import Utils from "platform/util/Utils";
import {PageType} from "kbd/enum/PageType";
import {PageNavigator} from "kbd/pages/PageNavigator";

enum ActivityTypeInfo {

    KYCPersonalInfoStarted = 180,
    KYCPersonalInfoFinished = 182,
    KYCAddressStarted = 181,
    KYCAddressFinished = 183,
    HelpRequested = 62,
    KYCFinancialDetailsStarted = 188,
    KYCFinancialDetailsFinished = 189,
    KYCTradingFamiliarityStarted = 200,
    KYCTradingFamiliarityFinished = 201,
    KYCTradingExperienceStarted = 184,
    KYCTradingExperienceFinished = 187,
    KYCTradingKnowledgeStarted = 185,
    KYCTradingKnowledgeFinished = 186,
    KYCTradingIntentionsStarted = 190,
    KYCSubmitted = 152,
    KYCTopUpPackagesStarted = 140,
    VerificationIdStarted = 120,
    VerificationIdFinished = 121,
    VerificationAddressStarted = 123,
    VerificationAddressFinished = 128,
    VerificationCcStarted = 129,
    VerificationCcFinished = 130,
    SignatureStarted = 143,
    SignatureSubmitted = 134,
    CustomActivity = 159,
}

namespace ActivityTypeInfo {

    export function pageOpenActivityType(pageType: PageType): ActivityTypeInfo {
        if (Utils.isNotNull(pageType)) {
            switch (pageType) {
                case PageType.KycPersonalInfo:
                    return ActivityTypeInfo.KYCPersonalInfoStarted;
                case PageType.KycAddress:
                    return ActivityTypeInfo.KYCAddressStarted;
                case PageType.KycFinancialDetails:
                    return ActivityTypeInfo.KYCFinancialDetailsStarted;
                case PageType.KycTradingFamiliarity:
                    return ActivityTypeInfo.KYCTradingFamiliarityStarted;
                case PageType.KycTradingExperience:
                    return ActivityTypeInfo.KYCTradingExperienceStarted;
                case PageType.KycTradingExperienceKnowledge:
                    return ActivityTypeInfo.KYCTradingKnowledgeStarted;
                case PageType.KycTradingExperienceIntentions:
                    return ActivityTypeInfo.KYCTradingIntentionsStarted;
                case PageType.KycIdentityVerification:
                    return ActivityTypeInfo.VerificationIdStarted;
                case PageType.KycAddressVerification:
                    return ActivityTypeInfo.VerificationAddressStarted;
                case PageType.KycPaymentVerification:
                    return ActivityTypeInfo.VerificationCcStarted;
                case PageType.KycCongratulation:
                    return ActivityTypeInfo.SignatureStarted;
            }
        }
        return null;
    }

    export function pageCloseActivityType(pageType: PageType): ActivityTypeInfo {
        if (Utils.isNotNull(pageType)) {
            switch (pageType) {
                case PageType.KycPersonalInfo:
                    return ActivityTypeInfo.KYCPersonalInfoFinished;
                case PageType.KycAddress:
                    return ActivityTypeInfo.KYCAddressFinished;
                case PageType.KycFinancialDetails:
                    return ActivityTypeInfo.KYCFinancialDetailsFinished;
                case PageType.KycTradingFamiliarity:
                    return ActivityTypeInfo.KYCTradingFamiliarityFinished;
                case PageType.KycTradingExperience:
                    return ActivityTypeInfo.KYCTradingExperienceFinished;
                case PageType.KycTradingExperienceKnowledge:
                    if (PageNavigator.hasPage(PageType.KycTradingExperienceKnowledge2)) {
                        return null;
                    }
                    return ActivityTypeInfo.KYCTradingKnowledgeFinished;
                case PageType.KycTradingExperienceKnowledge2:
                    return ActivityTypeInfo.KYCTradingKnowledgeFinished;
                case PageType.KycIdentityVerification:
                    return ActivityTypeInfo.VerificationIdFinished;
                case PageType.KycAddressVerification:
                    return ActivityTypeInfo.VerificationAddressFinished;
                case PageType.KycPaymentVerification:
                    return ActivityTypeInfo.VerificationCcFinished;
            }
        }
        return null;
    }
}

export {
    ActivityTypeInfo
}
