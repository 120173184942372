import {TranslationKey} from "kbd/enum/TranslationKey";
import Translations from "platform/translation/Translations";

enum ErrorCode {

    GeneralError = 1,
    SocialError = 1051,
    InvalidUsernameOrPassword = 10001,
    EmailAddressNotFound = 10002,
    PasswordsNotMatch = 10003,
    ChangePasswordRequired = 10004,

    // Sign Up error codes with prefix 10
    DUPLICATE_LEAD = 2,
    EMAIL_ADDRESS_ALREADY_EXIST = 1050, // Custom client error code,
}

namespace ErrorCode {

    export function trKey(errorCode: ErrorCode): TranslationKey {
        let errorTrKey: string = "server.error.code." + errorCode;
        if (!Translations.hasKey(errorTrKey)) {
            errorTrKey = "server.error.general";
        }
        return errorTrKey as TranslationKey;
    }
}

export {ErrorCode};
