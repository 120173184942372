import moment from "moment-mini";
import {SelectValue} from "kbd/component/primitive/TTSelect";
import Utils from "platform/util/Utils";
import {FieldType} from "kbd/enum/FieldType";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {KycStepStatus} from "kbd/enum/KycStepStatus";
import {KycStep} from "kbd/core/redux/kyc/KycReduxState";
import {FormikObject} from "typings";
import Jsonp from "platform/network/jsonp/Jsonp";
import Platform from "platform/Platform";
import {SetCountryCode} from "kbd/core/redux/app/AppReduxActions";
import {Configuration} from "kbd/core/configuration/Configuration";
import Script from "platform/network/script/Script";
import {LangCode} from "platform/enum/LangCode";
import {DocumentInfo} from "kbd/protocol/DocumentInfo";
import {ProofDocumentType} from "kbd/protocol/ProofDocumentType";
import {DocumentStatus} from "kbd/protocol/DocumentStatus";
import {PageNavigator} from "kbd/pages/PageNavigator";
import {StoreState} from "kbd/core/redux/StoreState";
import {KycStepType} from "kbd/enum/KycStepType";
import {ProofDocumentTypeInfo} from "kbd/protocol/ProofDocumentTypeInfo";
import {TSMap} from "typescript-map";
import {PageType} from "kbd/enum/PageType";
import {DocumentSide} from "kbd/enum/DocumentSide";
import {QuestionKey} from "kbd/protocol/QuestionKey";

export const EducationLevelAnswerEnableAreaOfEducation: number[] = [3, 4, 5, 6];
export const EmploymentStatusAnswerDisableCurrentIndustry: number[] = [4, 5];

export const YEARS: Array<SelectValue<number>> = Utils.range(1920, new Date().getFullYear() - 18).reverse().map((value: number) => ({title: value.toString(10), value}));

export const MONTHS = (langCode: LangCode): Array<SelectValue<number>> => {
    const options: Array<SelectValue<number>> = [];
    for (let i = 0; i < 12; i++) {
        const monthName: string = new Date(null, i + 1, null).toLocaleDateString(langCode, {month: "long"});
        options.push({
            title: LangCode.rtlCode(langCode) ? (i + 1).toString() : monthName.charAt(0).toUpperCase() + monthName.substring(1),
            value: i + 1
        });
    }
    return options;
};

export const DAYS_IN_MONTH = (month: any, year: any): number[] => {
    let m: number = parseInt(month, 10);
    let y: number = parseInt(year, 10);
    if (!Utils.greaterThen0(m)) {
        m = 1;
    }
    if (!Utils.greaterThen0(y)) {
        y = moment().year();
    }
    const daysInMonth: number = moment(y + "-" + (m < 10 ? "0" : "") + m, "YYYY-MM").daysInMonth();
    return Utils.range(1, daysInMonth);
};
export const DAYS = (month: number = 1, year: number = moment().year()): Array<SelectValue<number>> => {
    return DAYS_IN_MONTH(month, year).map((value: number) => ({title: value.toString(10), value}));
};

export const injectGeoScripts = (): void => {
    Jsonp.call("https://apps.visionsage.com/gc.js", {
        funcName: "chooseCountry"
    }).then((response: {country: string}) => {
        Platform.dispatch(SetCountryCode({
            countryCode: response.country
        }));
    });
};

export const injectGoogleMapsScripts = (): void => {
    const googleApiKey: string = Platform.config<Configuration>().googleApiKey;
    if (Utils.isNotEmpty(googleApiKey)) {
        Script.injectScript(
            "https://maps.googleapis.com/maps/api/js?libraries=places&key=" + googleApiKey,
            "GoogleApi"
        );
    }
};

export const uploadTypeSuffixTrKey = (docType: ProofDocumentType, docIndex: number): TranslationKey => {
    return docType === ProofDocumentType.ProofOfCC || docIndex > 1 ? null
        : docIndex === 0 ? TranslationKey.frontSide : TranslationKey.backSide;
};

export const DocumentCreditCardTitleTrKey = (side: DocumentSide): TranslationKey => {
    if (Utils.isNotNull(side)) {
        switch (side) {
            case DocumentSide.Front:
                return TranslationKey.ccFrontSide;
            case DocumentSide.Back:
                return TranslationKey.ccBackSide;
        }
    }
    return null;
};

export const kycStepPassed = (step: KycStep): boolean => {
    return step.status === KycStepStatus.Completed
        || step.status === KycStepStatus.Skipped
        || (step.status === KycStepStatus.InProgress && step.type === KycStepType.StartTrading);
};

const hasNextStepInStatus = (stepType: KycStepType, status: KycStepStatus): boolean => {
    const nextStep: KycStepType = PageNavigator.nextKycStep(stepType);
    if (nextStep) {
        const {steps} = Platform.reduxState<StoreState>().kyc;
        const step: KycStep = steps.get(nextStep);
        return Utils.isNotNull(step) && step.status === status;
    }
    return false;
};

export const hasNextStepPassed = (stepType: KycStepType): boolean => {
    return hasNextStepInStatus(stepType, KycStepStatus.Completed);
};

export const hasNextStepInProgressOrPassedOrSkip = (stepType: KycStepType): boolean => {
    return hasNextStepInStatus(stepType, KycStepStatus.InProgress) || hasNextStepInStatus(stepType, KycStepStatus.Skipped) || hasNextStepPassed(stepType);
};

export const lastInProgressStep = (): KycStepType => {
    const {steps} = Platform.reduxState<StoreState>().kyc;
    for (let i = 0; i < steps.length; i++) {
        const step: KycStep = steps.values()[i];
        if (step.status === KycStepStatus.InProgress) {
            return steps.keys()[i];
        }
    }
    return null;
};

export const fieldErrorTrKey = (fieldType: FieldType, formik: FormikObject<any>): TranslationKey => {
    const value: any = formik.values[fieldType];
    if (typeof value === "string" ? Utils.isEmpty(value) : Utils.isNull(value)) {
        return TranslationKey.required;
    }
    const error: string | {trKey: TranslationKey} = formik.errors[fieldType] as any;
    if (Utils.isNotNull(error) && typeof error === "object" && !Array.isArray(error)) {
        return error.trKey;
    }
    return "kyc.form.field.error." + fieldType as TranslationKey;
};

export const isDocumentApproved = (Document: DocumentInfo): boolean => {
    if (Document) {
        if (Document.Status === DocumentStatus.Approved || Document.Status === DocumentStatus.AutomaticallyApproved) {
            return true;
        }
    }
    return false;
};

export const isDocumentApprovedInReview = (Document: DocumentInfo): boolean => {
    if (Document) {
        if (Document.Status === DocumentStatus.ApprovedByComplianceOfficer) {
            return true;
        }
    }
    return false;
};

export const isDocumentRejected = (Document: DocumentInfo): boolean => {
    if (Document) {
        if (Document.Status === DocumentStatus.Rejected || Document.Status === DocumentStatus.AutomaticallyRejected) {
            return true;
        }
    }
    return false;
};

export const isDocumentInReview = (Document: DocumentInfo): boolean => {
    if (Document) {
        if (Document.Status === DocumentStatus.PendingReview || Document.Status === DocumentStatus.PendingAutomaticVerification) {
            return true;
        }
    }
    return false;
};

export const documentRejectionReason = (Document: DocumentInfo): string => {
    let reasons: string = "";
    if (Document && Utils.isArrayNotEmpty(Document.DocumentRejectionReasonLocalized)) {
        reasons = Document.DocumentRejectionReasonLocalized.join(", ");
    }
    return reasons;
};

export const FileToBase64: (file: File) => Promise<string> = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
});

export const isSkipDocumentsPage = (pageType: PageType): boolean => {
    const docType: ProofDocumentType = ProofDocumentType.fromPage(pageType);
    if (Utils.isNotNull(docType)) {
        const {documentTypes, documentStatuses} = Platform.reduxState<StoreState>().documents;
        const docInfo: ProofDocumentTypeInfo = documentTypes.get(docType);
        const docStatuses: TSMap<number | string, DocumentInfo> = documentStatuses.get(docType);
        return Utils.isNull(docInfo) || (!docInfo.IsNeeded && Utils.isMapEmpty(docStatuses));
    }
    return true;
};

export const getQuestionAnswerTitle = (questionKey: QuestionKey, answers: TSMap<QuestionKey, Array<SelectValue<number>>>, value: number): string => {
    if (questionKey && answers) {
        for (let i = 0; i < answers.size(); i++) {
            if (answers.keys()[i] === questionKey) {
                const qAnswers: Array<SelectValue<number>> = answers.values()[i];
                for (let j = 0; j < qAnswers.length; j++) {
                    if (qAnswers[j].value === value) {
                        return qAnswers[j].title;
                    }
                }
            }
        }
    }
    return null;
}
