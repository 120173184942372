import "react-app-polyfill/ie11";

import {Boot} from "kbd/entry/Boot";
import AsyncComponent from "platform/component/async/AsyncComponent";
import {PageType} from "kbd/enum/PageType";
import {ProductType} from "kbd/entry/ProductType";
import {BoardService} from "kbd/core/service/BoardService";
import {injectGeoScripts} from "kbd/core/util/KycUtil";

const BoardAsync = AsyncComponent(() => {
    return import("../component/root/BoardRoot");
});

const BoardMobileAsync = AsyncComponent(() => {
    return import("../component/root/BoardRootMob");
});

Boot({
    Desktop: BoardAsync,
    Mobile: BoardMobileAsync,
    product: ProductType.Board,
    defaultPage: PageType.Login,
    services: [
        {service: BoardService.instance()}
    ]
}).then(() => {
    injectGeoScripts();
}).catch((e) => {
    throw e;
});
