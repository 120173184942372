import jsonp from "jsonp";

export interface JsonpParams {

    funcName?: string;
    timeout?: number;
}

export default class Jsonp {

    private constructor() {}

    public static async call<T>(url: string, params?: JsonpParams): Promise<T> {
        return new Promise<any>(
            (resolve, reject) => {
                jsonp(url, {
                    name: (params && params.funcName) || "callback",
                    timeout: (params && params.timeout) || 5000,
                }, (err, data) => {
                    if (err) {
                        reject(err.message);
                    } else {
                        resolve(data);
                    }
                });
            }
        );
    }
}
