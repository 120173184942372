enum ModalType {

    LeaveKyc = "leaveKyc",
    PoliticalExposedPerson = "politicalExposedPerson",
    Signature = "signature",
    WebCam = "webCam",
    LoginTC = "loginTC",
    LoginNewPlatform = "loginNewPlatform",
    VerificationMethods = "verificationMethods",
    SoftLaunch = "softLaunch"
}

export {ModalType};
