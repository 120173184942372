export enum KycStepType {

    PersonalInfo = "PersonalInfo",
    Address = "Address",
    FinancialDetails = "FinancialDetails",
    TradingExperience = "TradingExperience",
    TopUp = "TopUp",
    TradingProfile = "TradingProfile",
    FinancialDetailsInternational = "FinancialDetailsInternational",
    IdentityVerification = "IdentityVerification",
    StartTrading = "StartTrading",
}
