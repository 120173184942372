export enum QuestionKey {

    // Financial details
    EducationLevel = "EducationLevel",
    AreaOfEducation = "AreaOfEducation",
    EmploymentStatus = "EmploymentStatus",
    EmploymentDetailsType = "EmploymentDetailsType",
    BusinessNature = "BusinessNature",
    PreviousEmploymentDetailsType = "PreviousEmploymentDetailsType",
    PreviousBusinessNature = "PreviousBusinessNature",
    EstimatedAnnualIncome = "EstimatedAnnualIncome",
    EstimatedNetWorth = "EstimatedNetWorth",
    SourceOfTotalWealthType = "SourceOfTotalWealthType",
    ExperienceLength = "ExperienceLength",

    // Trading familiarity
    ProductOrFinancialInstrumentType_ListedShares = "ProductOrFinancialInstrumentType_ListedShares",
    ProductOrFinancialInstrumentType_ExchangeTradedFunds = "ProductOrFinancialInstrumentType_ExchangeTradedFunds",
    ProductOrFinancialInstrumentType_FinancialDerivatives = "ProductOrFinancialInstrumentType_FinancialDerivatives",
    ProductOrFinancialInstrumentType_CFDs = "ProductOrFinancialInstrumentType_CFDs",
    ProductOrFinancialInstrumentType_Warrants = "ProductOrFinancialInstrumentType_Warrants",
    // Trading experience
    YearsOfExperience = "YearsOfExperience",
    TradingVolumeInTheLast12MonthsType = "TradingVolumeInTheLast12MonthsType",
    TradingFrequencyType = "TradingFrequencyType",
    ProfessionalQualificationType = "ProfessionalQualificationType",
    // Trading knowledge 1
    ComplianceQuizLeverageQuestionAnwsers = "ComplianceQuizLeverageQuestion",
    ComplianceQuizCDFShareQuestionAnwsers = "ComplianceQuizCDFShareQuestion",
    ComplianceQuizStopLossOrderAnswers = "ComplianceQuizStopLossOrder",
    // Trading knowledge 2
    ComplianceQuizForexQuestionAnswers = "ComplianceQuizForexQuestionAnswers",
    ComplianceQuizMarketMovesQuestionAnswers = "ComplianceQuizMarketMovesQuestionAnswers",
    ComplianceQuizRiskQuestionAnswers = "ComplianceQuizRiskQuestionAnswers",
    // Trading intentions
    TradingAccountPurpose = "TradingAccountPurpose",
    InvestmentHorizonType = "InvestmentHorizonType",
    TradingAmountCategory = "TradingAmountCategory",
    FundsSource = "FundsSource",
    MethodsForFundingAccountType = "MethodsForFundingAccountType",

    // Professional
    ProfTradingAmountCategory = "TradingAmountCategory",
    ProfInvestmentPortfolioType = "InvestmentPortfolioType", // Checkboxes config
    ProfFinancialInstrumentPortfolioSize = "FinancialInstrumentPortfolioSize", // Checkboxes answers
    ProfEmploymentDetailsType = "EmploymentDetailsType",

    // Deposit Limits
    ProductGovernanceInvestingObjectiveInCFDs = "ProductGovernanceInvestingObjectiveInCFDs",
    ProductGovernanceDesiredRisk = "ProductGovernanceDesiredRisk",
    ProductGovernanceYearlyDisposableIncomeCategory = "ProductGovernanceYearlyDisposableIncomeCategory"
}
