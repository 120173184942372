import moment, {MomentInputObject} from "moment-mini";
import Utils from "platform/util/Utils";

export class DateTimeFormat {

    private constructor() {
    }

    public static Of(value: number | MomentInputObject): moment.Moment {
        return moment(value);
    }

    public static formatDate = (value: number | MomentInputObject): string => {
        const momentDate = DateTimeFormat.Of(value);
        return momentDate.format(`DD/MM/YYYY`);
    }

    public static parseDate(value: string): moment.Moment {
        if (Utils.isNotNull(value)) {
            return moment(value, "DD/MM/YYYY");
        }
        return null;
    }

    public static now(): moment.Moment {
        return moment();
    }

    public static daysFromNow(value: string): number {
        const m: moment.Moment = DateTimeFormat.parseDate(value);
        if (m) {
            return m.diff(DateTimeFormat.now(), "days");
        }
        return null;
    }
}
