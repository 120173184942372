import Script from "platform/network/script/Script";
import {appleAuthHelpers, AppleAuthResponse} from "react-apple-signin-auth";
import {IConfig} from "signup/core/config/IConfig";
import {Configuration} from "signup/core/config/Configuration";
import {Logger} from "platform/logger/Logger";
import Utils from "platform/util/Utils";

export class SocialLogin {

    private static _Logger: Logger = Logger.Of("SocialLogin");
    private static _fbSDKInitialized: boolean = false;
    private static _googleDKInitialized: boolean = false;

    public static appleSignUp = async (clientId: string, redirectURI: string): Promise<string> => {
        const iConfig: IConfig = Configuration.Get();
        const useRedirect: boolean = Utils.isNotEmpty(redirectURI);
        return new Promise((resolve, reject) => {
            if (!window["AppleID"]) {
                this._Logger.debug("Apple inject script");
                Script.injectScript(appleAuthHelpers.APPLE_SCRIPT_SRC, "appleid-auth");
            }
            this._Logger.debug(`Apple sign In with clientId: ${clientId}. Use redirect: ${useRedirect}`);
            /*
                NOTE: redirect URL always should be!
                For "usePopup": true it should point on current page in the browser. i.e. window.location.href, in order apple script to receive postMessage with id_token.
                For "usePopup": false it should point on server api on order to handle POST redirect.
                in both cases return URLs should be listed at Apple Developer under "Certificates, Identifiers & Profiles" -> "Services IDs"
             */
            appleAuthHelpers.signIn({
                authOptions: {
                    clientId,
                    state: useRedirect ? redirectURI : null,
                    redirectURI: useRedirect ? iConfig.AppleRedirectApi : window.location.href,
                    usePopup: !useRedirect,
                    scope: "name email"
                },
                onSuccess: async (authResponse: AppleAuthResponse) => {
                    SocialLogin._Logger.debug("Apple success SignIn");
                    const {authorization} = authResponse;
                    resolve(authorization.id_token);
                },
                onError: (error) => {
                    if (!useRedirect) {
                        SocialLogin._Logger.warn(`Apple failed SignIn: ${error}`);
                        reject(error);
                    }
                },
            });
        });
    }

    private static facebookSdkInit = async (appId: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (SocialLogin._fbSDKInitialized) {
                resolve();
            }
            window.fbAsyncInit = (): void => {
                SocialLogin._Logger.debug(`Facebook init. App ID: ${appId}`);
                window.FB.init({
                    appId, // {your-app-id}
                    cookie: true,
                    xfbml: true,
                    version: 'v7.0' // {api-version}
                });
                window.FB.AppEvents.logPageView();
                SocialLogin._fbSDKInitialized = true;
                resolve();
            };
            this._Logger.debug("Facebook inject script");
            Script.injectScript("https://connect.facebook.net/en_US/sdk.js", "facebook-jssdk", undefined, () => {
                SocialLogin._Logger.warn("Facebook failed inject script");
                reject(new Error("Error injecting facebook api script"));
            });
        });
    }

    public static facebookSignUp = async (appId: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            this.facebookSdkInit(appId).then(() => {
                SocialLogin._Logger.debug("Facebook login");
                window.FB.login((response): void => {
                    SocialLogin._Logger.debug(`Facebook login status: ${response.status}`);
                    if (response.status === "connected") {
                        resolve(response.authResponse.accessToken);
                    } else {
                        reject(new Error("Failed Facebook signIn"));
                    }
                }, {scope: "public_profile,email"});
            }).catch((error) => {
                reject(error);
            });
        });
    }

    private static googleSdkInit = async (): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (SocialLogin._googleDKInitialized) {
                resolve();
            }
            this._Logger.debug("Google inject script.");
            Script.injectScript("https://accounts.google.com/gsi/client", "google-api", () => {
                SocialLogin._Logger.debug("Google auth2 initialized");
                SocialLogin._googleDKInitialized = true;
                resolve();
            }, () => {
                SocialLogin._Logger.warn("Google failed inject script");
                reject(new Error("Error injecting google api script"));
            });
        });
    }

    public static googleSignUp = async (client_id: string): Promise<string> => {
        return new Promise((resolve, reject) => {
            this.googleSdkInit()
                .then(() => {
                    const googleClient = window?.google?.accounts?.oauth2.initTokenClient({
                        client_id,
                        ux_mode: "popup",
                        scope: `openid profile email`,
                        callback: async (tokenResponse: any) => {
                            SocialLogin._Logger.debug("Got token response.");
                            if (tokenResponse.access_token){
                                resolve(tokenResponse.access_token);
                            } else {
                                SocialLogin._Logger.debug('tokenResponse error: ', tokenResponse);
                                reject(new Error("Google not authorized"));
                            }
                        },
                        error_callback: () => {
                            SocialLogin._Logger.debug("Failed get oauth2 access token");
                            reject(new Error("Failed get oauth2 access token"));
                        }
                    });
                    googleClient.requestAccessToken();
                }).catch(reject);
        });
    }
}
