import WebUtil from "platform/util/WebUtil";
import Platform from "platform/Platform";
import {LSKey} from "platform/storage/Storage";
import Utils from "platform/util/Utils";

const Token: string = WebUtil.urlParam("token") || WebUtil.urlParam("tokenId");
window.AuthToken = Token;

export class TokenManager {

    private constructor() {}

    public static async token(): Promise<string> {
        const token: string = await Platform.storage().getItem(LSKey.Token);
        if (Utils.isNotEmpty(Token) && (Utils.isEmpty(token) || token !== Token)) {
            Platform.storage().setItem(LSKey.Token, Token);
            return Token;
        }
        return Promise.resolve(token);
    }

    public static clear() {
        Platform.storage().removeItem(LSKey.Token);
        window.AuthToken = null;
    }
}
