import {SignUpEngine} from "signup/core/engine/SignUpEngine";
import {SignUpMetaManual} from "signup/core/type/SignUpMetaManual";
import {SignUpMetaSocial} from "signup/core/type/SignUpMetaSocial";
import {SignUpResult} from "signup/core/type/SignUpResult";
import Jsonp from "platform/network/jsonp/Jsonp";
import {RegLegalData} from "signup/core/config/RegLegalData";
import {Configuration} from "signup/core/config/Configuration";
import {LangCode} from "platform/enum/LangCode";
import {SignUpCountry} from "signup/core/type/SignUpCountry";
import {Http, HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {SocialMediaType} from "signup/enum/SocialMediaType";
import {SocialUtil} from "signup/core/SocialUtil";
import {LoginInWithToken} from "signup/core/type/LoginInWithToken";

export class TBSignUp {

    private _CountryCode: string;

    private GetCountryCode = (): Promise<string> => {
        return new Promise((resolve) => {
            Jsonp.call("https://apps.visionsage.com/gc.js", {
                funcName: "chooseCountry"
            }).then((response: {country: string}) => {
                resolve(response.country);
            });
        });
    }

    public CountryCode = async (): Promise<string> => {
        return this._CountryCode ? this._CountryCode : this._CountryCode = await this.GetCountryCode();
    }

    public UseAutoPassword = async (brandId: number, langCode: LangCode): Promise<boolean> => {
        const ld: RegLegalData = await Configuration.GetLegalData(brandId, langCode);
        return ld.IsAutoPassword;
    }

    public UseFullName = async (brandId: number, langCode: LangCode): Promise<boolean> => {
        const ld: RegLegalData = await Configuration.GetLegalData(brandId, langCode);
        return ld.IsFullName;
    }

    public Manual = async (meta: SignUpMetaManual): Promise<SignUpResult> => {
        const CountryCode: string = await this.CountryCode();
        return SignUpEngine.PerformManual(meta, CountryCode);
    }

    public Social = async (meta: SignUpMetaSocial): Promise<SignUpResult> => {
        const CountryCode: string = await this.CountryCode();
        return SignUpEngine.PerformSocial(meta, CountryCode);
    }

    public SocialToken = async (meta: SignUpMetaSocial): Promise<string> => {
        return SignUpEngine.SocialToken(meta);
    }

    public SocialUrl = async (meta: SignUpMetaSocial): Promise<string> => {
        const ld: RegLegalData = await Configuration.GetLegalData(meta.brandId, meta.langCode);
        let url: string;
        if (meta.socialType === SocialMediaType.Facebook) {
            url = SocialUtil.FacebookUrl(ld.FacebookAppId, meta.redirectURL);
        } else if (meta.socialType === SocialMediaType.Google) {
            url = SocialUtil.GoogleUrl(ld.GoogleClientId, meta.redirectURL);
        }
        return url;
    }

    public SocialLogin = async (meta: LoginInWithToken): Promise<string> => {
        return SignUpEngine.SocialLogin(meta);
    }

    public SignUpCountries = async (brandId: number): Promise<SignUpCountry[]> => {
        const URL: string = Configuration.GetCountriesUrl(brandId);
        const answer: [HttpReject, {countryList: SignUpCountry[]}] = await Utils.to(Http.getJson(URL));
        return answer[1]?.countryList || [];
    }
}
