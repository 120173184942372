import {SignUpRequest} from "signup/protocol/SignUpRequest";
import {RegLegalData} from "signup/core/config/RegLegalData";
import {ContactInfoSource} from "signup/enum/ContactInfoSource";
import {SignUpMetaManual} from "signup/core/type/SignUpMetaManual";
import {SignUpMetaSocial} from "signup/core/type/SignUpMetaSocial";
import Utils from "platform/util/Utils";
import WebUtil from "platform/util/WebUtil";
import {SignUpMetaCommon} from "signup/core/type/SignUpMetaCommon";
import {Configuration} from "signup/core/config/Configuration";
import {SignUpPlatform} from "signup/enum/SignUpPlatform";
import {LeadExperienceLevel} from "signup/enum/LeadExperienceLevel";
import {SocialMediaType} from "signup/enum/SocialMediaType";

export class RequestBuilder {

    private constructor() {}

    private static BuildCommon = (ld: RegLegalData, meta: SignUpMetaCommon, CountryCode: string): Partial<SignUpRequest> => {
        Utils.checkNotNull(ld);
        return {
            AdvertiserId: ld.AdvertiserId,
            AgencyId: ld.AgencyId,
            BrandId: ld.BrandId,
            CountryCode,
            EventId: Utils.uuid(),
            FunnelId: ld.FunnelId,
            FormId: ld.FormId,
            IsMobile: WebUtil.inWebView() ? null : WebUtil.isMobile(),
            FirstName: this.FormatInput(meta.firstName),
            LastName: this.FormatInput(meta.lastName),
            MobilePhone: `${meta.phoneCountryCode}${meta.phoneNumber}`,
            Platform: SignUpPlatform.Profit,
            PrivacyNotice: ld.PrivacyNotice,
            SkipEmailValidation: true,
            TermsAndConditions: ld.TermsAndConditions,
            VerificationCode: meta.verificationCode,
            ExperienceLevel: LeadExperienceLevel.Experienced,
        };
    }

    public static BuildManual = (ld: RegLegalData, meta: SignUpMetaManual, CountryCode: string): SignUpRequest => {
        Utils.checkNotNull(meta);
        return {
            ...this.BuildCommon(ld, meta, CountryCode),
            ContactInfoSource: ContactInfoSource.Manual,
            Email: meta.email,
            FullName: this.FormatInput(meta.fullName),
            Password: ld.IsAutoPassword ? this.GeneratePassword() : meta.password
        } as SignUpRequest;
    }

    public static BuildSocial = (ld: RegLegalData, meta: SignUpMetaSocial, CountryCode: string): SignUpRequest => {
        Utils.checkNotNull(meta);
        const IsFacebook: boolean = meta.socialType === SocialMediaType.Facebook;
        return {
            ...this.BuildCommon(ld, meta, CountryCode),
            ContactInfoSource: ContactInfoSource.From(meta.socialType),
            JWTToken: !IsFacebook ? meta.token : null,
            Token: IsFacebook ? meta.token : null
        } as SignUpRequest;
    }

    private static FormatInput = (v: string): string => {
        if (v) {
            return v.trim().replace(/\s\s+/g, " ");
        }
        return v;
    }

    private static GeneratePassword = () => {
        const {MinPasswordLength} = Configuration.Get();
        const characters: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        const outputString: string = Array(MinPasswordLength).fill(characters).map((x) => {
            return x[Math.floor(Math.random() * x.length)];
        }).join('');
        const regex1: RegExp = RegExp("([A-Z]{1,})");
        const regex2: RegExp = RegExp("([a-z]{1,})");
        const regex3: RegExp = RegExp("([0-9]{1,})");
        if (!regex1.test(outputString) || !regex2.test(outputString) || !regex3.test(outputString)) {
            return this.GeneratePassword();
        } else {
            return outputString;
        }
    }
}
