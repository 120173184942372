enum DpkType {

    Deposit = "deposit",
    ShowPoliticalExposedPerson = "dpk_show_political_exposed_person",
}

namespace DpkType {

    export function deserialize(v: string): DpkType {
        if (v) {
            switch (v) {
                case "dpk:deposit":
                    return DpkType.Deposit;
            }
        }
        return null;
    }
}

export {DpkType};
