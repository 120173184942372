export enum TranslationParam {

    brand = "brand",
    current = "current",
    email = "email",
    index = "index",
    name = "name",
    total = "total",
    url = "url",
    value = "value",
}
