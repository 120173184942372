import config from "../../../../resources/configuration/config.json";
import configQA from "../../../../resources/configuration/qa/config.json";
import configProd from "../../../../resources/configuration/prod/config.json";

import {IConfig} from "signup/core/config/IConfig";
import Utils from "platform/util/Utils";
import {Http, HttpReject} from "platform/network/http/Http";
import {BrandProps} from "platform/redux/core/CoreReduxState";
import {LangCode} from "platform/enum/LangCode";
import {RegLegalData} from "signup/core/config/RegLegalData";
import {TSMap} from "typescript-map";
import {Logger} from "platform/logger/Logger";

export class Configuration {

    private static _Logged: Logger = Logger.Of("Configuration");
    private static _RLDs: TSMap<string, RegLegalData> = new TSMap<string, RegLegalData>();
    private static _Config: IConfig;

    private constructor() {}

    public static Get = (): IConfig => {
        if (Utils.isNull(this._Config)) {
            const envConfig: IConfig = Utils.parseBoolean(process.env.IsProd) ? configProd : configQA;
            this._Config = Utils.merge({}, config, envConfig);
        }
        return this._Config;
    }

    public static GetCountriesUrl = (brandId: number): string => {
        const iConfig: IConfig = this.Get();
        const ldUrlDomain: string = iConfig.LegalData[brandId.toString()][0];
        return Utils.parseBoolean(process.env.IsProd) ? `${ldUrlDomain}nx-scripts/nxreg/nxreg_countryList.json`
            : `${ldUrlDomain}nx-scripts/dev/nxreg/nxreg_countryList.json`;
    }

    public static GetLegalData = async (brandId: number, langCode: LangCode): Promise<RegLegalData> => {
        this._Logged.debug(`Fetch config for brand: ${brandId}, langCode: ${langCode}`);
        const key: string = `${brandId}-${langCode}`;
        let rld: RegLegalData = this._RLDs.get(key);
        if (Utils.isNull(rld)) {
            const iConfig: IConfig = this.Get();
            const ldUrl: string = iConfig.LegalData[brandId.toString()].join("");
            const EnAnswer: [HttpReject, BrandProps] = await Utils.to(Http.getJson(`${ldUrl}en/Websites_full.json`));
            let LangAnswer: [HttpReject, BrandProps];
            if (langCode && langCode !== LangCode.EN) {
                LangAnswer = await Utils.to(Http.getJson(`${ldUrl}${langCode}/Websites_full.json`));
            }
            const LD: BrandProps = {keys: {...(EnAnswer[1]?.keys || {}), ...(LangAnswer ? LangAnswer[1]?.keys || {} : {})}};
            const IsFullName: boolean = Utils.parseBoolean(LD.keys?.nxReg_FullName?.value);
            const IsAutoPassword: boolean = Utils.parseBoolean(LD.keys.nxReg_AutoPassword?.value);
            const nxReg_ApiEndpoint: string = LD.keys.nxReg_ApiEndpoint?.value;
            const nxReg_AdvertiserId: string = LD.keys.nxReg_AdvertiserId?.value;
            const nxReg_AgencyId: string = LD.keys.nxReg_AgencyId?.value;
            const nxReg_FormId: string = LD.keys.nxReg_FormId?.value;
            const nxReg_FunnelID: string = LD.keys.nxReg_FunnelID?.value;
            const nxReg_FunnelId: string = LD.keys.nxReg_FunnelId?.value;
            const nxReg_AppleClientId: string = LD.keys.nxReg_AppleClientId?.value;
            const nxReg_FacebookAppId: string = LD.keys.nxReg_FacebookAppId?.value;
            const nxReg_GoogleClientId: string = LD.keys.nxReg_GoogleClientId?.value;
            this._RLDs.set(key, rld = {
                ApiUrl: nxReg_ApiEndpoint || iConfig.RegApi,
                AdvertiserId: nxReg_AdvertiserId || iConfig.AdvertiserId,
                AgencyId: nxReg_AgencyId || iConfig.AgencyId,
                BrandId: brandId,
                FormId: nxReg_FormId,
                FunnelId: nxReg_FunnelID || nxReg_FunnelId,
                IsFullName,
                IsAutoPassword,
                PrivacyNotice: iConfig.PrivacyNotice,
                TermsAndConditions: iConfig.TermsAndConditions,
                AppleClientId: nxReg_AppleClientId,
                FacebookAppId: nxReg_FacebookAppId,
                GoogleClientId: nxReg_GoogleClientId
            });
        }
        return rld;
    }
}
