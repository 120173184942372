import {SocialMediaType} from "signup/enum/SocialMediaType";
import Utils from "platform/util/Utils";

enum ContactInfoSource {

    Manual = "MANUAL",
    Facebook = "FACEBOOK",
    Google = "GOOGLE",
    Apple = "APPLE"
}

namespace ContactInfoSource {

    export function isSocial(source: ContactInfoSource): boolean {
        return source !== ContactInfoSource.Manual;
    }

    export function From(socialType: SocialMediaType): ContactInfoSource {
        if (Utils.isNotNull(socialType)) {
            switch (socialType) {
                case SocialMediaType.Apple:
                    return ContactInfoSource.Apple;
                case SocialMediaType.Facebook:
                    return ContactInfoSource.Facebook;
                case SocialMediaType.Google:
                    return ContactInfoSource.Google;
            }
        }
        return ContactInfoSource.Manual;
    }

    export function deserialize(socialType: string): ContactInfoSource {
        if (Utils.isNotEmpty(socialType)) {
            switch (socialType) {
                case "apple":
                    return ContactInfoSource.Apple;
                case "facebook":
                    return ContactInfoSource.Facebook;
                case "google":
                    return ContactInfoSource.Google;
            }
        }
        return null;
    }
}

export {
    ContactInfoSource
};
