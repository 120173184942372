import {TSMap} from "typescript-map";
import {IPage, IPageField, IPageFormRow} from "kbd/pages/IPage";
import {PageType} from "kbd/enum/PageType";
import Utils from "platform/util/Utils";
import {KycStepType} from "kbd/enum/KycStepType";
import {ProductType} from "kbd/entry/ProductType";
import Platform from "platform/Platform";
import {Configuration, KycProductConfig, NavigationConfig} from "kbd/core/configuration/Configuration";
import {QuestionKey} from "kbd/protocol/QuestionKey";
import {FieldType} from "kbd/enum/FieldType";
import {KycStep} from "kbd/core/redux/kyc/KycReduxState";
import {ComplianceFlowType} from "kbd/enum/ComplianceFlowType";
import WebUtil from "platform/util/WebUtil";
import {FinancialDetailsType} from "kbd/enum/FinancialDetailsType";

const COMPLIANCE_FORM_PAGES: PageType[] = [
    PageType.KycPersonalInfo,
    PageType.KycAddress,
    PageType.KycFinancialDetails,
    PageType.KycTradingFamiliarity,
    PageType.KycTradingExperience,
    PageType.KycTradingExperienceKnowledge,
    PageType.KycTradingExperienceKnowledge2,
    PageType.KycTradingExperienceIntentions,
];

const COMPLIANCE_DOCUMENT_PAGES: PageType[] = [
    PageType.KycIdentityVerification,
    PageType.KycAddressVerification,
    PageType.KycPaymentVerification,
];

const COMPLIANCE_FORM_STEPS: KycStepType[] = [
    KycStepType.PersonalInfo,
    KycStepType.Address,
    KycStepType.FinancialDetails,
    KycStepType.TradingExperience,
];

export class PageNavigator {

    private static _pages: TSMap<PageType, IPage> = new TSMap();
    private static _fields: TSMap<FieldType, IPageField> = new TSMap();
    private static _kycSteps: TSMap<KycStepType, IPage> = new TSMap();
    private static _menuPages: IPage[] = [];
    private static _hasDontHaveTin: boolean;

    public static setup = (complianceFlow?: ComplianceFlowType, financialDetailsType?: FinancialDetailsType): void => {
        const products = Platform.config<Configuration>().products;
        const productKey: string = ProductType.Kyc + (complianceFlow ? `_${complianceFlow}` : "");
        const productConfig: KycProductConfig = (products[productKey] || products[ProductType.Kyc]) as KycProductConfig;
        if (!Utils.isObjectEmpty(productConfig)) {
            const navConfig: NavigationConfig = productConfig.navigation;
            if (!Utils.isObjectEmpty(navConfig) && Utils.isArrayNotEmpty(navConfig.pages)) {
                const navPages: IPage[] = [...navConfig.pages].sort((p1: IPage, p2: IPage) => Utils.compareNumber(p1.index, p2.index));
                let PageIndex: number = 0;
                navPages.forEach((navPage: IPage) => {
                    const IsPageFinancialInternational: boolean = navPage.type === PageType.KycFinancialDetailsInternational;
                    if (IsPageFinancialInternational && Utils.greaterThen0(financialDetailsType) && financialDetailsType !== FinancialDetailsType.NotRequired) {
                        navPage.disabled = false;
                    }
                    if (!navPage.disabled) {
                        const index: number = PageIndex;
                        const type: PageType = PageType[navPage.type];
                        const kycStep: KycStepType = KycStepType[navPage.kycStep];
                        const page: IPage = {
                            type,
                            kycStep,
                            form: {
                                rows: []
                            },
                            fields: [],
                            questionKeys: [],
                            menu: navPage.menu,
                            relatedPages: [],
                            backNavigation: Utils.isNotNull(navPage.backNavigation) ? navPage.backNavigation : true,
                            close: Utils.isNotNull(navPage.close) ? navPage.close : true,
                            meta: Utils.isNotNull(navPage.meta) ? navPage.meta : {},
                            prev: () => PageNavigator._pages.values()[index - 1],
                            next: () => PageNavigator._pages.values()[index + 1]
                        };
                        if (Utils.isNotNull(navPage.form) && Utils.isArrayNotEmpty(navPage.form.rows)) {
                            page.form.generalValidation = navPage.form.generalValidation;
                            navPage.form.rows.forEach((navRow: IPageFormRow, inx: number) => {
                                const row: IPageFormRow = {};
                                page.form.rows.push(row);
                                if (Utils.isNotNull(navRow)) {
                                    let navFields: IPageField[] = navRow.fields;
                                    if (Utils.isArrayEmpty(navFields)) {
                                        navFields = WebUtil.isMobile() ? navRow.mobile?.fields : navRow.desktop?.fields;
                                    }
                                    if (Utils.isArrayNotEmpty(navFields)) {
                                        const fields: IPageField[] = [];
                                        navFields.forEach((navField: IPageField) => {
                                            if (IsPageFinancialInternational && navField.type === FieldType.TaxIdentificationNumber
                                                    && (financialDetailsType === FinancialDetailsType.RequiredWithCRS || financialDetailsType === FinancialDetailsType.RequiredFull)) {
                                                navField.disabled = false;
                                            }
                                            if (IsPageFinancialInternational && navField.type === FieldType.UsCitizen
                                                    && (financialDetailsType === FinancialDetailsType.RequiredWithFATCA || financialDetailsType === FinancialDetailsType.RequiredFull)) {
                                                navField.disabled = false;
                                            }
                                            if (!navField.disabled) {
                                                const field: IPageField = {...navField};
                                                if (Utils.isNull(field.index)) {
                                                    field.index = inx;
                                                }
                                                if (Utils.isNull(field.meta)) {
                                                    field.meta = {};
                                                }
                                                fields.push(field);
                                                page.fields.push(field);
                                                PageNavigator._fields.set(field.type, field);
                                                const questionKey: QuestionKey = FieldType.questionKey(field.type);
                                                if (questionKey) {
                                                    page.questionKeys.push(questionKey);
                                                }
                                                if (field.type === FieldType.IDontHaveTIN) {
                                                    PageNavigator._hasDontHaveTin = true;
                                                }
                                            }
                                        });
                                        if (Utils.isArrayNotEmpty(fields)) {
                                            row.fields = (fields);
                                        }
                                    }
                                }
                            });
                        }
                        PageIndex++;
                        PageNavigator._pages.set(type, page);
                        if (Utils.isNotNull(kycStep)) {
                            if (PageNavigator._kycSteps.keys().indexOf(kycStep) < 0) {
                                PageNavigator._kycSteps.set(kycStep, page);
                                PageNavigator._menuPages.push(page);
                            } else {
                                PageNavigator._kycSteps.get(kycStep).relatedPages.push(type);
                            }
                        } else if (navPage.menu) {
                            PageNavigator._menuPages.push(page);
                        }
                    }
                });
            }
        }
    }

    public static pages = (): IPage[] => {
        return PageNavigator._pages.values();
    }

    public static menuPages = (): IPage[] => {
        return PageNavigator._menuPages;
    }

    public static next = (from: PageType): IPage => {
        const page: IPage = PageNavigator._pages.get(from);
        if (Utils.isNotNull(page)) {
            return page.next();
        }
        return null;
    }

    public static prev = (from: PageType): IPage => {
        const page: IPage = PageNavigator._pages.get(from);
        if (Utils.isNotNull(page) && page.backNavigation) {
            return page.prev();
        }
        return null;
    }

    public static hasPage = (type: PageType): boolean => {
        return PageNavigator._pages.has(type);
    }

    public static isForward = (from: PageType, to: PageType): boolean => {
        const pageTypes: PageType[] = PageNavigator._pages.keys();
        const index: number = pageTypes.indexOf(from);
        for (let i = index + 1; i < pageTypes.length; i++) {
            if (pageTypes[i] === to) {
                return true;
            }
        }
        return false;
    }

    public static page = (type: PageType): IPage => {
        return PageNavigator._pages.get(type);
    }

    public static pageByStep = (kycStepType: KycStepType): IPage => {
        const pages: IPage[] = PageNavigator._pages.values();
        for (let i = 0; i < pages.length; i++) {
            const page: IPage = pages[i];
            if (page.kycStep === kycStepType) {
                return page;
            }
        }
        return null;
    }

    public static firstKycStep = (): KycStepType => {
        return PageNavigator._kycSteps.keys()[0];
    }

    public static nextKycStep = (kycStepType: KycStepType): KycStepType => {
        const kycStepTypes: KycStepType[] = PageNavigator._kycSteps.keys();
        return kycStepTypes[kycStepTypes.indexOf(kycStepType) + 1];
    }

    public static nextFormStep = (kycStepType: KycStepType): KycStepType => {
        const kycStepTypes: KycStepType[] = PageNavigator._kycSteps.keys();
        const index: number = kycStepTypes.indexOf(kycStepType);
        for (let i = index + 1; i < kycStepTypes.length; i++) {
            if (PageNavigator.isKycFormStep(kycStepTypes[i])) {
                return kycStepTypes[i];
            }
        }
        return null;
    }

    public static kycSteps = (): KycStepType[] => {
        return [...PageNavigator._kycSteps.keys()];
    }

    public static isKycFormStep = (stepType: KycStepType): boolean => {
        return COMPLIANCE_FORM_STEPS.indexOf(stepType) > -1;
    }

    public static isKycFormPage = (pageType: PageType): boolean => {
        return COMPLIANCE_FORM_PAGES.indexOf(pageType) > -1;
    }

    public static isDocumentPage = (pageType: PageType): boolean => {
        return COMPLIANCE_DOCUMENT_PAGES.indexOf(pageType) > -1;
    }

    public static isLastKycFormPage = (pageType: PageType): boolean => {
        const types: PageType[] = PageNavigator._pages.keys();
        const index: number = types.indexOf(pageType);
        for (let i = index + 1; i < types.length; i++) {
            if (COMPLIANCE_FORM_PAGES.indexOf(types[i]) > -1) {
                return false;
            }
        }
        return true;
    }

    public static lastKycFormPage = (): IPage => {
        let lastKycFormPage: IPage;
        const types: PageType[] = PageNavigator._pages.keys();
        for (let i = 0; i < types.length; i++) {
            if (COMPLIANCE_FORM_PAGES.indexOf(types[i]) > -1) {
                lastKycFormPage = PageNavigator._pages.values()[i];
            }
        }
        return lastKycFormPage;
    }

    public static lastKycFormStep = (steps: TSMap<KycStepType, KycStep>): KycStep => {
        for (let i = steps.length - 1; i >= 0; i--) {
            if (PageNavigator.isKycFormStep(steps.keys()[i])) {
                return steps.values()[i];
            }
        }
        return null;
    }

    public static isPageBeforeDeposit = (pageType: PageType): boolean => {
        const types: PageType[] = PageNavigator._pages.keys();
        for (let i = 0; i < types.length; i++) {
            const type: PageType = types[i];
            if (type === PageType.DepositTopUp) {
                break;
            }
            if (pageType === type) {
                return true;
            }
        }
        return false;
    }

    public static isDepositInTheMiddleOfKycForm = (): boolean => {
        const types: KycStepType[] = PageNavigator.kycSteps();
        const index: number = types.indexOf(KycStepType.TopUp);
        return PageNavigator.isKycFormStep(types[index + 1]);
    }

    public static stepIndex = (pageType: PageType): number => {
        const page: IPage = PageNavigator._pages.get(pageType);
        if (page && page.kycStep) {
            const index: number = PageNavigator._kycSteps.keys().indexOf(page.kycStep);
            if (index > -1) {
                return index + 1;
            }
        }
        return null;
    }

    public static hasDontHaveTin = (): boolean => {
        return PageNavigator._hasDontHaveTin;
    }

    public static hasField = (type: FieldType): boolean => {
        return PageNavigator._fields.has(type);
    }
}
