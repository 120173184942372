export enum ServiceType {

    App = "App",
    Kyc = "Kyc",
    Documents = "Documents",
    Deposit = "Deposit",
    Board = "Board",
    Professional = "Professional",
    DepositLimits = "DepositLimits",
    ShareInformation = "ShareInformation",
}
