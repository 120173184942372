import {Xhr} from "platform/network/xhr/Xhr";
import {ServerType} from "platform/enum/ServerType";
import {HttpReject} from "platform/network/http/Http";
import Utils from "platform/util/Utils";
import {BadRequestResponse} from "kbd/protocol/response/BadRequestResponse";
import Platform from "platform/Platform";
import {UrlType} from "platform/enum/UrlType";
import {Logger} from "platform/logger/Logger";
import {ShowPopup} from "platform/redux/popups/PopupsActions";
import {PopupActionType, PopupType} from "platform/redux/popups/PopupsReduxState";
import {TranslationKey} from "kbd/enum/TranslationKey";
import {TokenManager} from "kbd/core/util/TokenManager";
import WebUtil from "platform/util/WebUtil";
import {RNBridge} from "kbd/core/integration/RNBridge";
import {IntegrationReload} from "platform/integration/message/IntegrationReload";

export class XhrManager {

    private static logger: Logger = Logger.Of("XhrManager");

    private constructor() {}

    public static sendToRegister = (request: any, path: string): Promise<any> => {
        return Xhr.sendTo(ServerType.PleXop, request, path);
    }
    public static sendToLogin = (request: any, path: string): Promise<any> => {
        return Xhr.sendTo(ServerType.Login, request, "loginService.svc/json/" + path);
    }

    public static sendToLoginSecure(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Login, request, "loginsecureService.svc/json/" + path);
    }

    public static sendToLoginTC = (request: any, path: string): Promise<any> => {
        return Xhr.sendTo(ServerType.Login, request, "TermAndConditionsService.svc/json/" + path);
    }

    public static sendToConfig(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebSite/ComplianceConfigurationsService.svc/json/" + path);
    }

    public static sendToForm(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebsite/ComplianceFormService.svc/json/" + path);
    }

    public static sendToDocuments(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebsite/ComplianceDocumentService.svc/json/" + path);
    }

    public static sendToProfessional(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebsite/ComplianceProfessionalService.svc/json/" + path);
    }

    public static sendToGovernance(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.Compliance, request, "ComplianceWebsite/ProductGovernanceQuestionnaireService.svc/json/" + path);
    }

    public static sendToWebProfitService(request: any, path: string): Promise<any> {
        return this.sendAuthenticated(ServerType.TradingReal, request, "WebProfitServer/WebProfitClientService.svc/json/" + path);
    }

    private static async sendAuthenticated(server: ServerType, request: any, path: string): Promise<any> {
        const token: string = await TokenManager.token();
        const answer: [HttpReject, any] = await Utils.to(Xhr.sendTo(server, request, path, token));
        if (answer[0]) {
            if (Utils.isNotNull(answer[0].response)) {
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (response.IsSecurityException) {
                        XhrManager.logger.info("Handle security exception.");
                        TokenManager.clear();
                        const wk: any = (window as any).webkit;
                        const mjsi: any = (window as any).MobileJsInterface;
                        if (Utils.isNotNull(wk) && Utils.isNotNull(wk.messageHandlers) && Utils.isNotNull(wk.messageHandlers.jsHandle) && Utils.isNotNull(wk.messageHandlers.jsHandle.postMessage)) {
                            wk.messageHandlers.jsHandle.postMessage("openKYC");
                        } else if (Utils.isNotNull(mjsi)) {
                            mjsi.openKYC();
                        } else if (WebUtil.inRNWebView()) {
                            RNBridge.send(JSON.stringify(new IntegrationReload()));
                        } else {
                            const loginUrl: string = Platform.reduxState().core.urls.get(UrlType.NewLogin);
                            if (Utils.isNotEmpty(loginUrl)) {
                                XhrManager.logger.info("Navigate to login: " + loginUrl);
                                Platform.environment().redirect(loginUrl);
                            } else {
                                XhrManager.logger.info("Login url not configured!");
                            }
                        }
                    }
                } catch (e) {
                }
            }
            return Promise.reject(answer[0]);
        } else {
            return Promise.resolve(answer[1]);
        }
    }

    public static fetchRejectReason(answer: [HttpReject, any]): string {
        if (answer[0]) {
            if (Utils.isNotNull(answer[0].response)) {
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (!response.IsSecurityException) {
                        return response.LocalizeMessage || response.Message;
                    }
                } catch (e) {
                }
            }
        }
        return null;
    }

    public static notifyRejectReason(answer: [HttpReject, any]): void {
        if (answer[0]) {
            if (Utils.isNotNull(answer[0].response)) {
                try {
                    const response: BadRequestResponse = JSON.parse(answer[0].response);
                    if (!response.IsSecurityException) {
                        Platform.dispatch(ShowPopup({
                            popup: {
                                type: PopupType.ERROR,
                                message: {customValue: response.LocalizeMessage || response.Message, trKey: TranslationKey.serverErrorGeneral},
                                showClose: true,
                                actions: [{type: PopupActionType.OK}]
                            }
                        }));
                    }
                } catch (e) {
                }
            }
        }
    }
}
