import {SignUpMetaManual} from "signup/core/type/SignUpMetaManual";
import {SignUpMetaCommon} from "signup/core/type/SignUpMetaCommon";
import Utils from "platform/util/Utils";
import {SignUpFieldType} from "signup/enum/SignUpFieldType";
import {RegLegalData} from "signup/core/config/RegLegalData";
import {SignUpMetaSocial} from "signup/core/type/SignUpMetaSocial";
import {SocialMediaType} from "signup/enum/SocialMediaType";
import {Configuration} from "signup/core/config/Configuration";
import {PasswordRuleConfig} from "signup/core/config/IConfig";

type ValidationErrors = {[key: string]: string};

class Validation {

    public static IsValidFullName = (name: string): boolean => {
        const pattern: string = Configuration.Get().Validation.name;
        const regExp: RegExp = new RegExp(pattern);
        return regExp.test(name);
    }

    public static IsValidEmail = (email: string): boolean => {
        if (email) {
            const regExp: RegExp = new RegExp("(?!.*?[._%+-]{2,})(?=[a-zA-Z0-9@._%+-]{6,254}$)(^[a-zA-Z0-9])[a-zA-Z0-9._%+-]{1,64}([^._%+-]{1,})@(?!(gamail\.com)|(gamail\.top)|(gamil\.com)|(gmal\.com)|(gmail\.co$)|(gmail\.con)|(gmai\.com))(?:[a-zA-Z0-9-]{1,63}\\.){1,8}[a-zA-Z]{2,63}", "u");
            const valueMatch = email.match(regExp);
            return valueMatch && email === valueMatch[0];
        }
        return false;
    }

    public static ValidatePassword = (password: string): Array<{rule: PasswordRuleConfig, valid: boolean}> => {
        const rules: PasswordRuleConfig[] = Configuration.Get().Validation.password;
        const result: Array<{rule: PasswordRuleConfig, valid: boolean}> = [];
        if (Utils.isArrayNotEmpty(rules)) {
            rules.forEach((rule: PasswordRuleConfig) => {
                const regExp: RegExp = new RegExp(rule.pattern);
                const valid: boolean = regExp.test(password) === rule.valid;
                result.push({rule, valid});
            });
        }
        return result;
    }

    private static ValidateCommon = (ld: RegLegalData, meta: SignUpMetaCommon, errors: ValidationErrors): void => {
        if (!Utils.greaterThen0(meta?.brandId)) {
            errors["brandId"] = "Missing";
        }
        if (Utils.isEmpty(meta?.langCode)) {
            errors["langCode"] = "Missing";
        }
        if (Utils.isEmpty(meta?.phoneCountryCode)) {
            errors[SignUpFieldType.PhoneCountryCode] = "Missing";
        }
        if (Utils.isEmpty(meta?.phoneNumber)) {
            errors[SignUpFieldType.PhoneNumber] = "Missing";
        }
    }

    public static ValidateManual = (ld: RegLegalData, meta: SignUpMetaManual): ValidationErrors => {
        const errors: ValidationErrors = {};
        this.ValidateCommon(ld, meta, errors);
        if (ld.IsFullName) {
            if (Utils.isEmpty(meta?.fullName)) {
                errors[SignUpFieldType.FullName] = "Missing";
            }
        } else {
            if (Utils.isEmpty(meta?.firstName)) {
                errors[SignUpFieldType.FirstName] = "Missing";
            }
            if (Utils.isEmpty(meta?.lastName)) {
                errors[SignUpFieldType.LastName] = "Missing";
            }
        }
        if (Utils.isEmpty(meta?.email)) {
            errors[SignUpFieldType.Email] = "Missing";
        }
        if (!ld.IsAutoPassword && Utils.isEmpty(meta?.password)) {
            errors[SignUpFieldType.Password] = "Missing";
        }
        return errors;
    }

    public static ValidateSocial = (ld: RegLegalData, meta: SignUpMetaSocial): ValidationErrors => {
        const errors: ValidationErrors = {};
        this.ValidateCommon(ld, meta, errors);
        if (Utils.isNull(meta?.socialType)) {
            errors["socialType"] = "Missing";
        }
        return errors;
    }
}

export {
    Validation,
    ValidationErrors
};
