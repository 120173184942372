import WebUtil from "platform/util/WebUtil";

export class SocialUtil {

    private constructor() {
    }

    public static FacebookUrl = (appId: string, redirect_uri: string): string => {
        return WebUtil.addGetParams("https://www.facebook.com/v7.0/dialog/oauth", {
            client_id: appId,
            redirect_uri,
            response_type: "token",
            scope: "public_profile,email"
        });
    }

    public static GoogleUrl = (client_id: string, redirect_uri: string): string => {
        return WebUtil.addGetParams("https://accounts.google.com/o/oauth2/v2/auth", {
            scope: encodeURI("https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email"),
            include_granted_scopes: "true",
            redirect_uri,
            response_type: "token",
            client_id
        });
    }
}
